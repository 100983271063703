import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import StandardArticlesComponent from "../components/StandardArticlesComponent";
import {useEffect, useState} from "react";
import axios from "axios";
import {Helmet} from "react-helmet-async";

function NewsPage() {
    const [articles, setArticles] = useState({content: [], last: true});
    const [page, setPage] = useState(1);

    function getMoreArticles() {
        const parameters = {
            page: page
        };

        axios.get(process.env.REACT_APP_SERVER + "/article", {params: parameters})
            .then(response => {
                setArticles(prevState => ({
                    ...prevState,
                    content: [...prevState?.content, ...response.data.content],
                    last: response.data.last
                }))
            })
            .catch(err => console.log(err));

        setPage(prevState => prevState + 1);
    }


    useEffect(() => {
        getMoreArticles();
    }, []);

    return (
        <div className="main-div">
            <Helmet>
                <title>{"Laligapolska.pl - Aktualnosći"}</title>
                <meta
                    name={"Przeglądaj najnowsze artykuły, analizy i wywiady dotyczące La Liga oraz hiszpańskiej piłki nożnej. Znajdź interesujące treści" +
                        " na temat swoich ulubionych drużyn, zawodników i najważniejszych wydarzeń z boisk Hiszpanii."}/>
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <h1 className={"white-h1"}>Aktualności</h1>
                        <StandardArticlesComponent articles={articles}/>
                        {!articles.last && (
                            <button className="classic-button" onClick={getMoreArticles}>
                                Załaduj więcej
                            </button>
                        )}
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default NewsPage;