import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import StandardArticlesComponent from "../components/StandardArticlesComponent";
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";

function SearchArticlesPage() {
    const [articles, setArticles] = useState({content: [], last: true});
    const [page, setPage] = useState(1);
    const params = useParams();

    function getMoreArticles() {
        const parameters = {
            searchParam: params.searchParam, page: page
        };

        axios.get(process.env.REACT_APP_SERVER + "/article/search", {params: parameters})
            .then(response => {
                setArticles(prevState => ({
                    ...prevState, content: [...prevState?.content, ...response.data.content], last: response.data.last
                }))
            })
            .catch(err => console.log(err));

        setPage(prevState => prevState + 1);
    }


    useEffect(() => {
        getMoreArticles();
    }, []);

    return (
        <div className="main-div">
            <Helmet>
                <title>{`Laligapolska.pl - Wyszukaj ${params?.searchParam}`}</title>
                <meta
                    name="description"
                    content={`Przeglądaj najnowsze aktualności dotyczące ${params?.searchParam} na LaLigaPolska.pl.
                     Znajdź najświeższe informacje, analizy i wiadomości związane z ${params?.searchParam},
                      aby być na bieżąco z wydarzeniami w hiszpańskiej piłce nożnej.`}
                />
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        {articles.content.length === 0 && (
                            <h1 className={"white-h1"}>Nie znaleziono żadnego artykułu</h1>)}
                        <StandardArticlesComponent articles={articles}/>
                        {!articles.last && (<button className="classic-button" onClick={getMoreArticles}>
                            Załaduj więcej
                        </button>)}
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default SearchArticlesPage;
