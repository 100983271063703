import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import {Helmet} from "react-helmet-async";

function Regulations() {
    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Regulamin"}</title>
                <meta name="Przeczytaj nasz regulamin, aby dowiedzieć się o zasadach korzystania z LaLigaPolska.pl. Znajdziesz tutaj informacje na temat zasad,
                 obowiązków użytkowników oraz warunków korzystania z naszej strony internetowej."/>
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <div className={"policy-div"}>
                            <h1>Regulamin korzystania z serwisu LaligaPolska.pl</h1>

                            <h2>1. Informacje ogólne o serwisie</h2>
                            <p>1.1. LaligaPolska.pl jest serwisem internetowym poświęconym tematyce La Liga, w tym
                                wydarzeniom
                                związanym z hiszpańską ligą piłkarską, klubami, zawodnikami oraz analizom meczów.</p>
                            <p>1.2. Administracją serwisu zajmują się redaktorzy, którzy są odpowiedzialni za jego
                                prawidłowe
                                funkcjonowanie. Redaktorzy posiadają uprawnienia administratorów i zajmują się zarówno
                                tworzeniem
                                treści, jak i moderowaniem serwisu.</p>
                            <p>1.3. LaligaPolska.pl nie jest powiązana z oficjalnymi organami La Liga ani z
                                poszczególnymi
                                klubami,
                                zawodnikami czy organizacjami piłkarskimi. Serwis prezentuje opinie i analizy redakcji
                                oraz
                                tłumaczenia artykułów z różnych źródeł.</p>

                            <h2>2. Informacje o Regulaminie</h2>
                            <p>2.1. Regulamin określa zasady korzystania z serwisu LaligaPolska.pl i jest wiążący dla
                                wszystkich
                                Użytkowników.</p>
                            <p>2.2. Regulamin jest dostępny pod adresem: <a
                                href="https://www.laligapolska.pl/regulations">https://www.laligapolska.pl/regulations</a>.
                            </p>
                            <p>2.3. Korzystanie z serwisu oznacza akceptację postanowień Regulaminu.</p>
                            <p>2.4. Serwis zastrzega sobie prawo do zmiany Regulaminu. Zmiany będą ogłaszane na stronie
                                serwisu.</p>

                            <h2>3. Warunki korzystania z serwisu</h2>
                            <p>3.1. Użytkownik zobowiązuje się korzystać z serwisu zgodnie z obowiązującym prawem oraz
                                zasadami
                                netykiety.</p>
                            <p>3.2. Zabronione jest publikowanie treści naruszających prawa autorskie, dobra osobiste
                                innych
                                osób, a
                                także treści wulgarnych, obraźliwych, czy propagujących przemoc i nienawiść.</p>
                            <p>3.3. Serwis ma prawo do moderowania i usuwania treści, które są sprzeczne z Regulaminem
                                lub
                                obowiązującym prawem.</p>

                            <h2>4. Prawa autorskie</h2>
                            <p>4.1. Wszystkie materiały zamieszczone na stronie LaligaPolska.pl, w tym teksty, zdjęcia,
                                grafiki, są
                                własnością serwisu lub osób trzecich i są chronione prawami autorskimi.</p>
                            <p>4.2. Kopiowanie, rozpowszechnianie lub wykorzystywanie materiałów z serwisu bez zgody
                                właściciela
                                praw autorskich jest zabronione.</p>

                            <h2>5. Odpowiedzialność</h2>
                            <p>5.1. Serwis nie ponosi odpowiedzialności za treści zamieszczane przez Użytkowników oraz
                                za
                                ewentualne
                                szkody powstałe w wyniku korzystania z serwisu.</p>
                            <p>5.2. Serwis zastrzega sobie prawo do wprowadzenia zmian w funkcjonalności, dostępności i
                                zawartości
                                strony.</p>

                            <h2>6. Kontakt</h2>
                            <p>6.1. Wszelkie pytania dotyczące Regulaminu lub działania serwisu prosimy kierować na
                                adres
                                e-mail: <a
                                    href="mailto:laligapolska2021@gmail.com">laligapolska2021@gmail.com</a>.</p>

                            <h2>7. Postanowienia końcowe</h2>
                            <p>7.1. Regulamin wchodzi w życie z dniem jego opublikowania na stronie LaligaPolska.pl.</p>
                        </div>
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>

        </div>
    )
}

export default Regulations