import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import {Helmet} from "react-helmet-async";

function Policy() {
    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Polityka Prywatności"}</title>
                <meta name="Zapoznaj się z naszą polityką prywatności i dowiedz się, jak chronimy Twoje dane osobowe na LaLigaPolska.pl.
                 Przeczytaj o zasadach dotyczących gromadzenia, przetwarzania i przechowywania informacji użytkowników."/>
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <div className={"policy-div"}>
                            <h1>Polityka Prywatności Serwisu LaLigaPolska.pl</h1>

                            <p>Data ostatniej aktualizacji: 15-08-2024</p>

                            <h2>1. Kto jest administratorem Twoich danych osobowych?</h2>
                            <p>Administratorem Twoich danych osobowych jest serwis LaLigaPolska.pl. Możesz się z nami
                                skontaktować
                                za
                                pośrednictwem formularza kontaktowego dostępnego na stronie wysyłając
                                e-mail na adres: <a
                                    href="mailto:laligapolska2021@gmail.com">laligapolska2021@gmail.com</a>.
                            </p>

                            <h2>2. Co to są dane osobowe i z jakich danych korzystamy?</h2>
                            <p>W obrębie serwisu LaLigaPolska.pl dane osobowe to informacje, które mogą identyfikować
                                osobę
                                fizyczną,
                                takie jak:</p>
                            <ul>
                                <li>adres e-mail;</li>
                                <li>adres IP;</li>
                                <li>unikalna nazwa Użytkownika (nick);</li>
                                <li>hasło;</li>
                                <li>dane logowań;</li>
                                <li>pliki cookies.</li>
                            </ul>
                            <p>Serwis LaLigaPolska.pl korzysta z narzędzi analitycznych i reklamowych, takich jak Google
                                Analytics,
                                oraz
                                stosuje profilowanie w celu dostosowania wyświetlanych reklam i monitorowania
                                funkcjonowania
                                serwisu.
                                Wszelkie inne dane osobowe, które Użytkownik zamieszcza w serwisie (np. w komentarzach,
                                wiadomościach
                                prywatnych lub profilu) są umieszczane na jego własną odpowiedzialność.</p>

                            <h2>3. Dlaczego zbieramy Twoje dane?</h2>
                            <p>Dane osobowe są zbierane w celu realizacji umowy o świadczenie usług, na podstawie
                                uzasadnionego
                                interesu
                                administratora, a także:</p>
                            <ul>
                                <li>zapewnienia bezpieczeństwa korzystania z serwisu;</li>
                                <li>przeprowadzania analiz i zbierania statystyk;</li>
                                <li>zbierania opinii o funkcjonowaniu serwisu;</li>
                                <li>personalizacji treści i reklam;</li>
                                <li>możliwości zakładania konta, komentowania, oceniania i korzystania z innych funkcji;
                                </li>
                                <li>monitorowania bezpieczeństwa logowań i zgodności z Regulaminem.</li>
                            </ul>

                            <h2>4. Jaka jest podstawa prawna zbierania danych osobowych w Serwisie?</h2>
                            <p>Dane osobowe są przetwarzane w celu realizacji umowy oraz na podstawie uzasadnionego
                                interesu
                                administratora zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE)
                                2016/679.</p>

                            <h2>5. Kto jest odbiorcą lub współadministratorem Twoich danych osobowych?</h2>
                            <p>Serwis LaLigaPolska.pl korzysta z usług podmiotów współadministrujących dane osobowe,
                                takich
                                jak
                                Yieldbird Sp. z o.o., Adrino Sp. z o.o., oraz Google LLC, które pomagają w dostosowaniu
                                treści
                                reklamowych i monitorowaniu funkcjonowania serwisu. W przypadku odmowy zgody na
                                profilowanie, możesz
                                zmienić ustawienia plików cookies w przeglądarce, co może wpłynąć na funkcjonalność
                                serwisu.</p>

                            <h2>6. Jak długo przechowujemy Twoje dane?</h2>
                            <p>Dane osobowe są przechowywane do momentu wycofania zgody przez Użytkownika. W niektórych
                                przypadkach
                                dane
                                mogą być przechowywane dłużej, jeśli jest to wymagane przepisami prawa lub w celu
                                ochrony
                                własnych
                                dóbr
                                osobistych.</p>

                            <h2>7. Jakie są Twoje prawa?</h2>
                            <p>Masz prawo do:</p>
                            <ul>
                                <li>informacji i komunikacji z administratorem;</li>
                                <li>dostępu do danych osobowych;</li>
                                <li>sprostowania danych;</li>
                                <li>bycia zapomnianym;</li>
                                <li>ograniczenia przetwarzania;</li>
                                <li>przenoszenia danych;</li>
                                <li>sprzeciwu wobec przetwarzania;</li>
                                <li>wniesienia skargi do organu nadzorczego.</li>
                            </ul>

                            <h2>8. Zgoda na przetwarzanie Twoich danych osobowych</h2>
                            <p>Korzystając z serwisu LaLigaPolska.pl, wyrażasz zgodę na przetwarzanie swoich danych
                                osobowych w
                                sposób
                                opisany w Polityce Prywatności. Akceptacja Regulaminu serwisu oznacza zgodę na
                                przetwarzanie
                                danych.
                                Jeśli nie zgadzasz się na przetwarzanie danych, nie powinieneś korzystać z serwisu.</p>
                        </div>
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Policy