import React, {createContext, useContext, useState} from "react";
import {useCookies} from "react-cookie";

const CookiesContext = createContext();


export function CookiesProvider({children}) {
    const [cookie] = useCookies(["cookiesAccepted"])
    const [isCookiesWindowActive, setIsCookiesWindowActive] = useState(!cookie.cookiesAccepted)


    return (
        <CookiesContext.Provider
            value={{
                isCookiesWindowActive,
                setIsCookiesWindowActive
            }}
        >
            {children}
        </CookiesContext.Provider>
    );
}

export function useCookiesWindow() {
    return useContext(CookiesContext);
}