import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import {Helmet} from "react-helmet-async";

function ErrorPage() {
    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Strona błędu"}</title>
                <meta name={"Przepraszamy, strona której szukasz, nie została odnaleziona." +
                    " Sprawdź, czy adres URL jest poprawny, lub wróć na stronę główną LaLigaPolska.pl, aby śledzić najnowsze wiadomości o hiszpańskiej piłce nożnej"}/>
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <h1 className={"white-h1"}>Błąd 404 - Strona o podanym adresie nie istnieje.</h1>
                    </div>

                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>

        </div>
    )
}

export default ErrorPage