import {Helmet} from "react-helmet-async";
import calendar from "../uploads/calendar-black.png";
import {calculateArticleTime, showComments} from "../components/CalculationsComponent";
import articleIcon from "../uploads/article-black.png";
import commentIcon from "../uploads/comments-black.png";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {useNotification} from "../context/NotificationContext";
import {useLoader} from "../context/LoaderContext";

function ArticleEditPage() {
    const [article, setArticle] = useState(null)
    const {setNotification} = useNotification()
    const {activateLoader, desActivateLoader} = useLoader()
    const location = useLocation()
    const [url] = useState(new URLSearchParams(location.search).get("url"))

    useEffect(() => {
        function fetchArticle() {
            activateLoader()
            const params = {
                url: url
            }
            axios.get(process.env.REACT_APP_SERVER + "/article-url", {params: params})
                .then(response => {
                    setArticle(response.data)
                    desActivateLoader()
                })
                .catch(err => {
                    if (process.env.MODE === "dev") {
                        console.log(err)
                    }
                    desActivateLoader()
                    setNotification("Nie udało sie pobrać artykułu")
                })
        }
        fetchArticle()
    }, []);

    function handleTitleChange(event) {
        const newArticle = {...article, title: event.target.value}
        setArticle(newArticle)
    }

    function handleContentChange(event) {
        const newArticle = {...article, content: event.target.value}
        setArticle(newArticle)
    }

    function handleTypeChange(event) {
        const newArticle = {...article, type: event.target.value}
        setArticle(newArticle)
    }

    function handleCheckBoxChange(event) {
        const newArticle = {...article, isRecommended: event.target.checked}
        setArticle(newArticle)
    }

    const handleTagChange = (index, event) => {
        const newTags = article.tags.map((tag, idx) => {
            if (idx === index) {
                return event.target.value;
            }
            return tag;
        });
        const newArticle = {
            ...article,
            tags: newTags
        };
        setArticle(newArticle)
    }
    function handleCoverUrlChange(event) {
        const newArticle = {...article, coverUrl: event.target.value}
        setArticle(newArticle)
    }

    function onArticleEditClick(article) {
        activateLoader()
        axios.patch(process.env.REACT_APP_SERVER + "/admin/article-edit", article)
            .then(() => {
                desActivateLoader()
                setNotification("Zaktualizowano artykuł.")
            })
            .catch(err => {
                if(process.env.MODE === "dev"){
                    console.log(err)
                }
                desActivateLoader()
                setNotification("Nie udało się zaktualizować artykułu.")
            })
    }



    return (
        <div className={"main-div"}>
            <Helmet>
                <title>Laligapolska.pl - Edytuj artykuł</title>
                <meta name="description" content="Panel administratora. Edytuj artykuł."/>
            </Helmet>

            <div className={"article-edit-div"}>
                <div className={"article-preview-div"}>
                    <input
                        onChange={event => handleCoverUrlChange(event)}
                        defaultValue={article?.coverUrl}></input>
                    <textarea className={"articles-management-title-area"}
                              onChange={event => handleTitleChange(event)}
                              defaultValue={article?.title}></textarea>
                    <textarea className={"articles-management-text-area"}
                              onChange={event => handleContentChange(event)}
                              defaultValue={article?.content}/>
                    {article?.tags?.map((tag, tagIndex) => (
                        <input
                            key={tagIndex}
                            onChange={event => handleTagChange(tagIndex, event)}
                            defaultValue={tag}></input>
                    ))}
                    <label>STANDARD</label>
                    <input type={"radio"} name={"type"} value={"STANDARD"} defaultChecked={true}
                           onChange={event => handleTypeChange(event)}/>
                    <label>IMPORTANT</label>
                    <input type={"radio"} name={"type"} value={"IMPORTANT"}
                           onChange={event => handleTypeChange(event)}/>
                    <label>MAIN</label>
                    <input type={"radio"} name={"type"} value={"MAIN"}
                           onChange={event => handleTypeChange(event)}/>
                    <button onClick={() => onArticleEditClick(article)}>Edytuj</button>
                </div>
                <div className={"article-content-div"}>
                    <div className={"author-info"}>
                        <h1 className={"article-title"}>{article?.title}</h1>
                        <p className={"article-author"}>{article?.author}</p>
                        <div className={"article-time-div"}>
                        <span><img className={"icon"} alt={"calendar"}
                                   src={calendar}/>{calculateArticleTime(article?.addTime)}</span>
                            <span><img className={"icon"} alt={"source"} src={articleIcon}/>{article?.source}</span>
                        </div>
                        <p><img className={"icon"} alt={"comment"}
                                src={commentIcon}/>{showComments(article?.comments)}</p>
                    </div>
                    <img alt={"article"} className={"article-image"}
                         src={article?.coverUrl}/>
                    <article
                        dangerouslySetInnerHTML={{__html: article?.content}}
                        className={"article-text"}/>
                    {article?.tags.map((tag, index) => (
                        <p key={index}>{tag}</p>
                    ))}
                    <p>TYP {article?.type}</p>
                    <p>Rekomendowany?</p>
                    <input type={"checkbox"} onChange={event => handleCheckBoxChange(event)}/>
                </div>
            </div>
        </div>
    )
}

export default ArticleEditPage