import {useEffect, useState} from "react";
import axios from "axios";
import ArticleTranslatedPreview from "./ArticleTranslatedPreview";

function ArticlesTranslatedManagement() {
    const [articles, setArticles] = useState([])
    const [articlePreview, setArticlePreview] = useState(null)
    const [isPreviewActive, setIsPreviewActive] = useState(false)


    function fetchTranslatedArticles() {
        axios.get(process.env.REACT_APP_SERVER + "/admin/translated-articles")
            .then(response => setArticles(response.data))
            .catch(err => {
                if(process.env.MODE === "dev") {
                    console.log(err)
                }
            })
    }

    useEffect(() => {
        fetchTranslatedArticles()
    }, []);


    function onArticleClick(article) {
        setArticlePreview(article)
        setIsPreviewActive(true)
    }

    return (
        <div className={"articles-management"}>
            {articles?.map((article, articleIndex) => (
                <p onClick={() => onArticleClick(article)} key={articleIndex}
                   className={"articles-management-list"}>{article?.title}</p>
            ))}
            {isPreviewActive && (<ArticleTranslatedPreview article={articlePreview}
                                                           serPreviewActive={setIsPreviewActive}/>)}
        </div>
    )
}

export default ArticlesTranslatedManagement