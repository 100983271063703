import React, {createContext, useContext, useState} from "react";

const NotificationContext = createContext();

export function NotificationProvider({children}) {
    const [isNotificationActive, setIsNotificationActive] = useState(false)
    const [notificationContent, setNotificationContent] = useState(null)

    function setNotification(content) {
        setIsNotificationActive(true)
        setNotificationContent(content)
        setTimeout(() => setIsNotificationActive(false), 3000)
    }

    return (
        <NotificationContext.Provider
            value={{
                isNotificationActive,
                notificationContent,
                setNotification
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

export function useNotification() {
    return useContext(NotificationContext);
}