import time from "../uploads/time.png";
import like from "../uploads/like.png";
import refresh from "../uploads/refresh.png";
import {calculateArticleTime} from "./CalculationsComponent";
import replyIcon from "../uploads/reply.png";
import axios from "axios";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ReactComponent as Like} from '../uploads/like.svg';
import {useLoader} from "../context/LoaderContext";
import {useNotification} from "../context/NotificationContext";


function CommentsComponent({article}) {

    const [token] = useState(localStorage.getItem("jwt"));
    const [addCommentText, setAddCommentText] = useState("");
    const [parentCommentId, setParentCommentId] = useState(null)
    const [comments, setComments] = useState([]);
    const [commentsSort, setCommentsSort] = useState("addTime,DESC");
    const [page, setPage] = useState(1);
    const params = useParams();
    const {activateLoader, desActivateLoader} = useLoader()
    const {setNotification} = useNotification()


    useEffect(() => {
        function scrollToComment() {
            const hash = window.location.hash.substring(1)
            const comment = document.getElementById(`article-with-replies-${hash}`);
            if (comment) {
                comment?.scrollIntoView({behavior: 'smooth'});
            }
        }

        scrollToComment()
    }, [comments]);

    function onReplyClick(comment) {
        let commentDiv = document.getElementById("article-with-replies-" + comment.id);
        let addCommentDiv = document.querySelector(".login-add-comments-div");
        let textarea = document.getElementById("add-comment-textarea");
        let cancelButton = document.getElementById("cancel-reply-button");
        setParentCommentId(comment.id)
        cancelButton.classList.remove("hidden");
        commentDiv.appendChild(addCommentDiv)
        textarea.value = "@" + comment.author + " "
        textarea.focus()

    }

    function updateCommentRate(event, commentId) {
        let parameters = {
            commentId: commentId
        };
        axios.get(process.env.REACT_APP_SERVER + "/vote/comment-voted", {params: parameters})
            .then(response => {
                if (response.data === true) {
                    setNotification("Już głosowałeś/aś")
                } else {
                    axios.patch(process.env.REACT_APP_SERVER + "/vote/comment", null, {params: parameters})
                        .then(response => {
                            const updatedLikes = response.data.likes;
                            setComments(prevState => ({
                                ...prevState,
                                content: prevState.content.map(comment => {
                                    if (comment.id === commentId) {
                                        return {...comment, likes: updatedLikes};
                                    }

                                    const updatedReplies = comment.replies.map(reply => {
                                        if (reply.id === commentId) {
                                            return {...reply, likes: updatedLikes};
                                        }
                                        return reply;
                                    });

                                    return {...comment, replies: updatedReplies};
                                })
                            }));
                        })
                        .catch(err => {
                            if(process.env.MODE === "dev") {
                                console.log(err)
                            }
                        });
                }
            })
            .catch(err => {
                if (err.response.status === 403 || err.response.status === 401) {
                    window.location.href = "/login";
                    console.log(err.response);
                }
            });
    }

    function showCommentOptions(commentId) {
        if (window.innerWidth > 1024) {
            let commentOptionDiv = document.getElementById("comment-options-" + commentId);
            commentOptionDiv?.classList.add("active");
        }
    }

    function hideCommentOptions(commentId) {
        if (window.innerWidth > 1024) {
            let commentOptionDiv = document.getElementById("comment-options-" + commentId);
            commentOptionDiv?.classList.remove("active");
        }
    }

    function getFirstArticleComments() {
        activateLoader()
        setPage(1);
        let parameters = {
            articleUrl: params?.url,
            page: page,
            sort: commentsSort
        };
        axios.get(process.env.REACT_APP_SERVER + "/article-comments", {params: parameters})
            .then(response => {
                setComments(response.data)
                desActivateLoader()
            })
            .catch(err => {
                desActivateLoader()
                if(process.env.MODE === "dev"){
                    console.log(err)
                }
            });
    }

    useEffect(() => {
        getFirstArticleComments();
    }, [commentsSort]);


    function getMoreComments() {
        activateLoader()
        let parameters = {
            articleUrl: params?.url,
            page: page + 1,
            sort: commentsSort
        };
        if (!comments?.last) {
            axios.get(process.env.REACT_APP_SERVER + "/article-comments", {params: parameters})
                .then(response => {
                    setComments(prevState => ({
                        ...prevState,
                        content: [...prevState?.content, ...response.data.content],
                        last: response.data.last
                    }));
                    setPage(prevState => prevState + 1);
                    desActivateLoader()
                })
                .catch(err => {
                    desActivateLoader()
                    if(process.env.MODE === "dev"){
                        console.log(err)
                    }
                });
        }
    }

    function showReplies(parentCommentId) {
        let repliesDiv = document.getElementById("article-replies-" + parentCommentId);
        repliesDiv.classList.add("active")
    }


    function addComment() {
        let data = {
            articleUrl: params.url,
            parentCommentId: parentCommentId,
            textContent: addCommentText
        };

        axios.post(process.env.REACT_APP_SERVER + "/comment", data)
            .then(() => {
                getFirstArticleComments()
                setAddCommentText("")
                showReplies(parentCommentId)
            })
            .catch(err => {
                if(process.env.MODE === "dev") {
                    console.log(err)
                }
            });
    }

    function sortCommentsBy(sortBy) {
        setPage(1);
        if (sortBy === "time") {
            setCommentsSort("addTime,DESC");
        } else if (sortBy === "likes") {
            setCommentsSort("likes,DESC");
        } else {
            console.log("Incorrect value of comment sorting type.");
        }
    }


    function cancelReply() {
        let addCommentDiv = document.querySelector(".login-add-comments-div");
        let commentDiv = document.querySelector(".comment-div");
        let textarea = document.getElementById("add-comment-textarea");
        let cancelButton = document.getElementById("cancel-reply-button");
        setParentCommentId(null)
        cancelButton.classList.add("hidden");
        commentDiv.prepend(addCommentDiv);
        textarea.value = ""
    }


    function deleteComment(commentId) {
        let parameters = {
            commentId: commentId
        };
        axios.delete(process.env.REACT_APP_SERVER + "/comment", {params: parameters})
            .then(() => getFirstArticleComments())
            .catch(err => {
                if(process.env.MODE === "dev") {
                    console.log(err)
                }
            });
    }

    function onAvatarClick(author) {
        window.location.href = `/profile/${author}`
    }

    return (
        <div className={"article-comments-div"}>
            <div className={"comment-div"}>
                {token ? (
                    <div className={"login-add-comments-div"}>
                            <textarea
                                value={addCommentText}
                                id={"add-comment-textarea"} maxLength={5000}
                                onChange={event => setAddCommentText(event.target.value)}
                                placeholder={"Podziel się swoją opinią..."}></textarea>
                        <span className={"comment-chars-span"}>{addCommentText?.length}/5000 znaków</span>
                        <div className={"add-comment-options"}>
                            <button id={"cancel-reply-button"}
                                    onClick={cancelReply} className={"classic-button hidden"}>Anuluj
                            </button>
                            <button onClick={addComment} className={"classic-button"}>Dodaj</button>
                        </div>
                    </div>
                ) : (
                    <div className={"login-add-comments-div"}>
                        Zaloguj się aby móc dodawać komentarze.
                        <p><a href={"/login"}>Zaloguj się </a> lub <a href={"/register"}>zarejestruj swoje
                            konto.</a></p>
                    </div>
                )}
                <div className={"article-comments-header"}>
                    <h3>Komentarze ({article?.comments})</h3>
                    <p onClick={() => sortCommentsBy("time")}>
                        <img className={"comments-icon"} src={time}
                             alt={"time"}/>Najnowsze</p>
                    <p onClick={() => sortCommentsBy("likes")}>
                        <img className={"comments-icon"} src={like}
                             alt={"like"}/>Najlepsze</p>
                    <img onClick={getFirstArticleComments} id={"refresh-icon"} className={"comments-icon"}
                         src={refresh} alt={"refresh"}/>
                </div>
                {comments?.content?.map((comment, index) => (
                        <div id={"article-with-replies-" + comment?.id}
                             key={index}
                             className={"article-with-replies"}>
                            <div onMouseLeave={() => hideCommentOptions(comment?.id)}
                                 onMouseOver={() => showCommentOptions(comment?.id)}
                                 key={index} className={"article-comments-content"}>
                                <div onClick={() => onAvatarClick(comment?.author)} className={"comment-avatar-div"}>
                                    <img alt={"avatar"} src={comment?.authorAvatarUrl} className={"comment-avatar"}/>
                                </div>
                                <div className={"comment-div"}>
                                    <div className={"comment-info"}>
                                        <p>{comment?.author} <span>{calculateArticleTime(comment?.addTime)}</span></p>
                                        <p onClick={event => updateCommentRate(event, comment?.id)}>
                                            {comment?.likes} <Like className={"icon"}/></p>
                                    </div>
                                    <div className={"comment-content"}>
                                        <p>
                                            {comment?.content}
                                        </p>
                                    </div>
                                    {comment?.replies.length > 0 && (
                                        <div className={"show-replies-div"}>
                                            <img className={"icon"}
                                                 alt={"reply"}
                                                 src={replyIcon}/>
                                            <p onClick={() => showReplies(comment?.id)}>Pokaż odpowiedzi</p>
                                        </div>
                                    )}

                                    {token && (
                                        <div id={"comment-options-" + comment?.id} className={"comment-options"}>
                                            {(comment?.author === JSON.parse(localStorage.getItem("userInfo"))?.username)
                                            || (localStorage.getItem("role") === "ADMIN") ? (
                                                <>
                                                    <span onClick={() => deleteComment(comment?.id)}
                                                          className={"comment-options-el"}>Usuń</span>
                                                    <span onClick={() => onReplyClick(comment)}
                                                          className={"comment-options-el"}>Odpowiedz</span>
                                                </>
                                            ) : (
                                                <>
                                                        <span onClick={() => onReplyClick(comment)}
                                                              className={"comment-options-el"}>Odpowiedz</span>
                                                    <span className={"comment-options-el"}>Zgłoś
                                </span>
                                                </>
                                            )}
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className={"article-replies"}
                                 id={"article-replies-" + comment?.id}>
                                {comment?.replies?.map((reply, index) => (
                                    <div
                                        key={index}
                                        onMouseLeave={() => hideCommentOptions(reply?.id)}
                                        onMouseOver={() => showCommentOptions(reply?.id)}
                                        className={"article-comments-content, article-replies-div"}
                                    >
                                        <div onClick={() => onAvatarClick(reply?.author)} className={"comment-avatar-div"}>
                                            <img alt={"avatar"} src={reply?.authorAvatarUrl}
                                                 className={"comment-avatar"}/>
                                        </div>
                                        <div className={"comment-div"}>
                                            <div className={"comment-info"}>
                                                <p>{reply?.author} <span>{calculateArticleTime(reply?.addTime)}</span>
                                                </p>
                                                <p onClick={event => updateCommentRate(event, reply?.id)}>
                                                    {reply?.likes} <Like className={"icon"}/>
                                                </p>
                                            </div>
                                            <div className={"comment-content"}>
                                                <p>{reply?.content}</p>
                                            </div>
                                            {token && (
                                                <div id={"comment-options-" + reply?.id} className={"comment-options"}>
                                                    {reply?.author === JSON.parse(localStorage.getItem("userInfo"))?.username ? (
                                                        <>
                                                        <span onClick={() => deleteComment(reply?.id)}
                                                              className={"comment-options-el"}>Usuń</span>
                                                            <span onClick={() => onReplyClick(comment)}
                                                                  className={"comment-options-el"}>Odpowiedz</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                <span
                                                    onClick={() => onReplyClick(comment)}
                                                    className={"comment-options-el"}>
                                                    Odpowiedz
                                                </span>
                                                            <span className={"comment-options-el"}>Zgłoś</span>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )}
            </div>
            {
                !comments?.last && (
                    <button className={"classic-button"} onClick={getMoreComments}>Załaduj więcej</button>
                )
            }
        </div>
    )
}

export default CommentsComponent