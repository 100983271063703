import logo from "../uploads/logo.png";
import {useEffect, useState} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {useNotification} from "../context/NotificationContext";
import {Helmet} from "react-helmet-async";

function ResetPassPage() {
    const [password, setPassword] = useState(null)
    const [passwordConfirm, setPasswordConfirm] = useState(null)
    const location = useLocation()
    const [token] = useState(new URLSearchParams(location.search).get("token"))
    const {setNotification} = useNotification()


    useEffect(() => {
        if (password && passwordConfirm && password !== passwordConfirm) {
            setNotification("Hasła różnią się od siebie.")
            document.getElementById("change-pass-button").classList?.add("button-disabled")
            document.getElementById("change-pass-button").disabled = true

        } else {
            document.getElementById("change-pass-button").classList?.remove("button-disabled")
            document.getElementById("change-pass-button").disabled = false
        }
    }, [password, passwordConfirm]);

    function onResetSubmit(event) {
        event.preventDefault()
        let data = {
            token: token,
            password: password,
            passwordConfirm: passwordConfirm
        }
        axios.patch(process.env.REACT_APP_SERVER + "/public/reset-password", data)
            .then(() => window.location.href = "/login")
            .catch(err => {
                setNotification("Nie udało się zresetować hasła.")
                console.log(err)
            })
    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Resetuj Hasło"}</title>
                <meta name="Zresetuj swoje hasło na LaLigaPolska.pl. Wprowadź nowe hasło, aby odzyskać dostęp do swojego konta. Bezpieczny i
                 prosty proces zmiany hasła pomoże Ci szybko wrócić do śledzenia najnowszych informacji o hiszpańskiej piłce nożnej."/>
            </Helmet>
            <main className={"login-container"}>
                <img className={"logo"} alt={"logo"} src={logo}/>
                <h1>Wpisz nowe hasło</h1>
                <form onSubmit={event => onResetSubmit(event)} className={"login-form"}>
                    <input onChange={event => setPassword(event.target.value)}
                           required={true} type={"password"} placeholder={"Hasło"}
                           pattern={"^(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{8,}$"}
                           title={"Hasło musi zawierać co najmniej 8 znaków (1 mała litera, 1 wielka litera, 1 cyfra, 1 znak specjalny)."}
                    />
                    <input onChange={event => setPasswordConfirm(event.target.value)}
                           required={true} type={"password"}
                           pattern={"^(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{8,}$"}
                           title={"Hasło musi zawierać co najmniej 8 znaków (1 mała litera, 1 wielka litera, 1 cyfra, 1 znak specjalny)."}
                           placeholder={"Potwierdź hasło"}/>
                    <button id={"change-pass-button"} type={"submit"}>Zmień hasło</button>
                </form>
            </main>
        </div>
    )
}

export default ResetPassPage