import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AxiosInterceptor from "./interceptors/AxiosInterceptor";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <GoogleOAuthProvider
            clientId={"351536378649-5uhup2n4e8m0ommdd67sfaiv81hc5m5k.apps.googleusercontent.com"}>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8472043461397762"
                    crossOrigin="anonymous"></script>
            <AxiosInterceptor/>
            <App/>
        </GoogleOAuthProvider>
    </>
);
