import React, {createContext, useContext, useState} from "react";

const LoaderContext = createContext();

export function LoaderProvider({children}) {
    const [isLoaderActive, setIsLoaderActive] = useState(false)

    function activateLoader() {
        let body = document.querySelector("body");
        body.style.opacity = 0.5;
        body.style.pointerEvents = "none"
        setIsLoaderActive(true)
    }

    function desActivateLoader() {
        let body = document.querySelector("body");
        body.style.opacity = 1;
        body.style.pointerEvents = "auto"
        setIsLoaderActive(false)
    }

    return (
        <LoaderContext.Provider
            value={{
                isLoaderActive,
                activateLoader,
                desActivateLoader
            }}
        >
            {children}
        </LoaderContext.Provider>
    );
}

export function useLoader() {
    return useContext(LoaderContext);
}