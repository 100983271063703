import logo from "../uploads/logo.png"
import {useEffect, useState} from "react";
import axios from "axios";
import {useLoader} from "../context/LoaderContext";
import {useNotification} from "../context/NotificationContext";
import {Helmet} from "react-helmet-async";

function RegisterPage() {

    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPass, setConfirmPass] = useState(null)
    const {activateLoader, desActivateLoader} = useLoader()
    const {setNotification} = useNotification()


    useEffect(() => {
        if (password  && confirmPass && password !== confirmPass) {
            setNotification("Hasła różnią się od siebie.")
            document.getElementById("register-button").classList?.add("button-disabled")
            document.getElementById("register-button").disabled = true

        } else {
            document.getElementById("register-button").classList?.remove("button-disabled")
            document.getElementById("register-button").disabled = false
        }
    }, [password, confirmPass]);


    function onRegisterSubmit(event) {
        event.preventDefault()
        activateLoader()
        let data = {
            username: username,
            email: email,
            password: password,
            confirmPassword: confirmPass
        }
        axios.post(process.env.REACT_APP_SERVER + "/register", data)
            .then(() => {
                desActivateLoader()
                setNotification(`Na adres email ${email} wysłano link aktywacyjny.`)
            })
            .catch(err => {
                setNotification("Email lub nazwa użytkownika zajęte.")
                console.log(err)
                desActivateLoader()
            })
    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Zarejestruj się"}</title>
                <meta name="Przeglądaj nasze archiwum ankiet, aby zobaczyć wyniki i analizy przeprowadzonych badań dotyczących La Liga
                i hiszpańskiej piłki nożnej. Znajdź informacje o opinii fanów i trendach w futbolu hiszpańskim."/>
            </Helmet>
            <main className={"login-container"}>
                <img className={"logo"} alt={"logo"} src={logo}/>
                <h1>Zarejestruj się</h1>
                <h2>
                    Dołącz grona kibiców z całej Polski. Zakładając konto na LaLigaPolska.pl
                    dołączasz do unikalnej społeczności fanów hiszpańskiej piłki nożnej!</h2>
                <form onSubmit={event => onRegisterSubmit(event)} className={"login-form"}>
                    <input onChange={event => setEmail(event.target.value)}
                           type={"email"} placeholder={"Adres email"}/>
                    <input onChange={event => setUsername(event.target.value)}
                           required={true} minLength={2} placeholder={"Nazwa użytkownika"}/>
                    <input type={"password"} onChange={event => setPassword(event.target.value)}
                           maxLength={50}
                           required={true} pattern={"^(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{8,}$"}
                           title={"Hasło musi zawierać co najmniej 8 znaków (1 mała litera, 1 wielka litera, 1 cyfra, 1 znak specjalny)."}
                           placeholder={"Hasło"}/>
                    <input type={"password"} onChange={event => setConfirmPass(event.target.value)}
                           required={true} placeholder={"Powtórz hasło"} maxLength={50}/>
                    <p>Zakładając konto akceptujesz regulamin serwisu laligapolska.pl oraz zapoznałaś(eś) się z naszą
                        polityką prywatności.</p>
                    <button id={"register-button"} type={"submit"}>Zarejestruj się</button>
                </form>
                <p>Masz już konto? <a href={"/login"}> Zaloguj się.</a></p>
            </main>
        </div>
    )
}

export default RegisterPage