import calendar from "../uploads/calendar.svg";
import {calculateArticleTime, showComments} from "./CalculationsComponent";
import comment from "../uploads/comment.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import {useLoader} from "../context/LoaderContext";

function StandardArticlesComponent({articles}) {
    const [standardArticles, setStandardArticles] = useState([])
    const {activateLoader, desActivateLoader} = useLoader()

    useEffect(() => {

        function fetchStandardArticles() {
            activateLoader(true)
            axios.get(process.env.REACT_APP_SERVER + "/article-type", {params: {type: "STANDARD"}})
                .then(response => {
                    setStandardArticles(response.data.content)
                    desActivateLoader()
                })
                .catch(err => {
                    desActivateLoader()
                    if(process.env.MODE === "dev"){
                        console.log(err)
                    }

                })

        }

        if (articles === undefined) {
            fetchStandardArticles()
        } else {
            setStandardArticles(articles?.content)

        }
    }, [articles]);


    return (
        <div className={"third-articles-div"}>
            {standardArticles?.map((article, index) => (
                <div className={"third-article-div"} key={index}>
                    <div className={"third-articles-header"}>
                                <span>
                                    <img alt={"calendar"} src={calendar}/> {calculateArticleTime(article?.addTime)}
                                </span>
                        <span>
                                    <img alt={"comment"} src={comment}/> {showComments(article?.comments)}
                                </span>
                    </div>
                    <div className={"third-article-details-div"}>
                        <div className={"third-article-img-div"}>
                            <img alt={"article"}
                                 src={article?.coverUrl}
                                 className={"third-article-photo"}/>
                            <a href={`/article/tag/${article?.tags?.at(0)}`}
                               className={"third-article-category"}>{article?.tags?.at(0)}</a>
                        </div>
                        <a href={"/article/" + article?.url}
                           className={"third-article-content-div"}>
                            <p>{article?.title}</p>
                        </a>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default StandardArticlesComponent