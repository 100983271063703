function Footer() {
    return (
        <footer className={"footer"}>
            <ul className={"footer-menu"}>
                <li><a href={"/policy"}>Polityka prywatności</a></li>
                <li><a href={"/regulations"}>Regulamin</a></li>
                <li><a href={"/article/1-chat"}>Czat</a></li>
            </ul>
            <p>Hello, Monetag</p>
        </footer>
    )
}

export default Footer