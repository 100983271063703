import React, {createContext, useContext} from "react";

const MobileMenuContext = createContext();

export function MobileMenuProvider({ children }) {

    return (
        <MobileMenuContext.Provider
            value={{
            }}
        >
            {children}
        </MobileMenuContext.Provider>
    );
}

export function useMobileMenu() {
    return useContext(MobileMenuContext);
}