import calendar from "../uploads/calendar-black.png"
import commentIcon from "../uploads/comments-black.png"
import articleIcon from "../uploads/article-black.png"
import {ReactComponent as Like} from '../uploads/like.svg';
import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import {useEffect, useLayoutEffect, useState} from "react";
import axios from "axios";
import {calculateArticleTime, showComments} from "../components/CalculationsComponent";
import StandardArticlesComponent from "../components/StandardArticlesComponent";
import CommentsComponent from "../components/CommentsComponent";
import {useLocation, useParams} from "react-router-dom";
import {useNotification} from "../context/NotificationContext";
import {Helmet} from "react-helmet-async";
import {useLoader} from "../context/LoaderContext";


function ArticlePage() {
    const [article, setArticle] = useState(null);
    const [userRole] = useState(localStorage.getItem("role"))
    let params = useParams();
    const location = useLocation()
    const {setNotification} = useNotification()
    const {activateLoader, desActivateLoader} = useLoader()
    const [articleDescription, setArticleDescription] = useState("Laligapolska.pl")


    useLayoutEffect(() => {
        function scrollToComment() {
            let comment = document.getElementById(location.hash?.substring(1))
            comment?.scrollIntoView({behavior: "smooth"})
        }

        if (location.hash)
            setTimeout(scrollToComment, 1000)
    }, [location]);

    useEffect(() => {
        if (!params) {
            window.location.href = "/error"
        }

        function getArticleContent() {
            axios.get(process.env.REACT_APP_SERVER + "/article-url", {params: params})
                .then(response => {
                    setArticle(response.data)
                    setArticleDescription(response.data.content.substring(0, response.data.content.indexOf(".") + 1))
                })
                .catch(() => window.location.href = "/error");
        }

        getArticleContent();
    }, [params]);

    function updateArticleRate() {
        let parameters = {
            articleUrl: params?.url
        };
        axios.get(process.env.REACT_APP_SERVER + "/vote/article-voted", {params: parameters})
            .then(response => {
                if (response.data === true) {
                    setNotification("Już głosowałeś/aś.")
                } else {
                    axios.patch(process.env.REACT_APP_SERVER + "/vote/article", null, {params: parameters})
                        .then(response => {
                            setArticle(prevArticle => ({
                                ...prevArticle,
                                rate: response.data
                            }));
                        })
                        .catch(err => console.log(err));
                }
            })
            .catch(err => {
                if (err.response.status === 403 || err.response.status === 401) {
                    window.location.href = "/login";
                    console.log(err.response);
                }
            });
    }


    function onDeleteArticleClick(url) {
        params = {articleUrl: url}
        axios.delete(process.env.REACT_APP_SERVER + "/article", {params: params})
            .then(() => window.location.href = "/")
            .catch(err => console.log(err))
    }

    function updateRecommend(isRecommended) {
        const data = {
            articleId: article?.id,
            isRecommended: isRecommended
        }
        axios.patch(process.env.REACT_APP_SERVER + "/admin/is-recommended", data)
            .then(() => setNotification("Zaktualizowano artykuł."))
            .catch(err => {
                setNotification("Nie udało się zaktualizować artykułu.")
                console.log(err)
            })
    }


    function publicOnFb() {
        activateLoader()
        const params = {
            photoUrl: article?.coverUrl
        }
        axios.post(process.env.REACT_APP_SERVER + "/admin/facebook-photo", null, {params: params})
            .then(photoResponse => {
                const data = {
                    message: `${article?.title} \nhttps://www.laligapolska.pl/article/${article?.url}`,
                    attached_media: [{media_fbid: photoResponse.data?.id}]
                }
                axios.post(process.env.REACT_APP_SERVER + "/admin/facebook-post", data)
                    .then(() => {
                        desActivateLoader()
                        setNotification("Dodano wszystko pomyślnie.")
                    })
                    .catch(err => {
                        desActivateLoader()
                        console.log(err)
                        setNotification("Dodano tylko zdjęcie do fb.")
                    })
            })
    }


    return (
        <main className={"main-div"}>
            <Helmet>
                <title>{`Laligapolska.pl - ${article?.title}`}</title>
                <meta name="description" content={articleDescription}/>
                <meta property="og:title" content={article?.title}/>
                <meta property="og:description" content={articleDescription}/>
                <meta property="og:image" content={article?.coverUrl}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <div className={"article-content-div"}>
                            <div className={"author-info"}>
                                <h1 className={"article-title"}>{article?.title}</h1>
                                <p className={"article-author"}>{article?.author}</p>
                                <div className={"article-time-div"}>
                        <span><img className={"icon"} alt={"calendar"}
                                   src={calendar}/>{calculateArticleTime(article?.addTime)}</span>
                                    <span><img className={"icon"} alt={"source"}
                                               src={articleIcon}/>{article?.source}</span>
                                </div>
                                <p><img className={"icon"} alt={"comment"}
                                        src={commentIcon}/>{showComments(article?.comments)}
                                </p>
                                {userRole === "ADMIN" && (
                                    <>
                                        <button onClick={publicOnFb}
                                                className={"classic-button"}> Opublikuj na Fb
                                        </button>
                                        {article?.isRecommended && (
                                            <button onClick={() => updateRecommend(false)}
                                                    className={"classic-button"}>Usuń z rekomendowanych
                                            </button>
                                        )}
                                        {!article?.isRecommended && (
                                            <button onClick={() => updateRecommend(true)}
                                                    className={"classic-button"}>Dodaj do rekomendowanych
                                            </button>
                                        )}
                                        <div className={"article-admin-opt"}>
                                            <button onClick={() => window.location.href = `/article-edit?url=${article?.url}`}
                                                    className={"classic-button"}>Edytuj
                                            </button>
                                            <button onClick={() => onDeleteArticleClick(article?.url)}
                                                    className={"classic-button"}>Usuń
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <img alt={"article"} className={"article-image"}
                                 src={article?.coverUrl}/>
                            <article
                                dangerouslySetInnerHTML={{__html: article?.content}}
                                className={"article-text"}/>

                        </div>
                        <div className={"under-article-div"}>
                            <div className={"article-rate-div"}>
                                <Like onClick={updateArticleRate} className={"recommendation-icon"}/>
                                <span className={"article-rate"}>{article?.rate}</span>
                                <span className={"recommend-article"}>&lt;- Poleć artykuł</span>
                            </div>
                            <div className={"article-hashtags-div"}>
                                {article?.tags?.map((tag, index) => (
                                    <a key={index} className={"article-hashtag"} href={`/article/tag/${tag}`}>{tag}</a>
                                ))}
                            </div>
                        </div>
                        <CommentsComponent
                            article={article}/>
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                        <StandardArticlesComponent/>
                    </div>
                </div>
            </main>
        </main>
    )
        ;
}

export default ArticlePage;
