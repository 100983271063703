import {useEffect, useState} from "react";
import axios from "axios";
import {calculateMaxVotes} from "../functions/CalculateMaxVotes"
import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import {Helmet} from "react-helmet-async";

function QuestionsArchivePage() {

    const [questions, setQuestions] = useState(null)
    const [page, setPage] = useState(1)

    function fetchQuestionsArchive() {
        let params = {
            page: page
        }
        axios.get(process.env.REACT_APP_SERVER + "/public/question-archive", {params: params})
            .then(response => {
                if (questions != null) {
                    setQuestions(prevState => ({
                        ...prevState,
                        content: [...prevState?.content, ...response.data.content],
                        last: response.data.last
                    }));
                } else {
                    setQuestions(response.data)
                }
                setPage(prevState => prevState + 1)

            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        fetchQuestionsArchive()
    }, []);


    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Archiwum Ankiet"}</title>
                <meta name="Przeglądaj nasze archiwum ankiet, aby zobaczyć wyniki i analizy przeprowadzonych badań dotyczących
                 La Liga i hiszpańskiej piłki nożnej. Znajdź informacje o opinii fanów i trendach w futbolu hiszpańskim."/>
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <h1 className={"white-h1"}>Archiwum ankiet</h1>
                        <ul className={"archive-list"}>
                            {questions?.content?.map((question, index) => (
                                <li key={index} className="question-div">
                                    <p className="question">{question?.question}</p>
                                    {question?.answers?.map((answer, index) => (
                                        <div key={index} className="question-el">
                                            <span className={"question-span"}>{answer?.text}</span>
                                            <progress value={answer?.votes} max={calculateMaxVotes(question)}/>
                                            <span>{(answer?.votes / calculateMaxVotes(question) * 100).toFixed(0)}%</span>
                                        </div>
                                    ))}
                                </li>
                            ))}
                        </ul>
                        {
                            !questions?.last && (
                                <button className={"classic-button"} onClick={fetchQuestionsArchive}>Załaduj więcej</button>
                            )
                        }
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default QuestionsArchivePage