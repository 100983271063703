import {useNotification} from "../context/NotificationContext";

function NotificationComponent() {
    const {isNotificationActive, notificationContent} = useNotification();

    return (
        isNotificationActive && (
            <div className={"notification-div"}>
                <p className={"notification-p"}>{notificationContent}</p>
            </div>
        ))
}

export default NotificationComponent