import recommendation from "../uploads/recommendation.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import {useLoader} from "../context/LoaderContext";

function RecommendedArticlesComponent() {
    const [articles, setArticles] = useState([])
    const {activateLoader, desActivateLoader} = useLoader()
    useEffect(() => {
        activateLoader()

        function fetchRecommendedArticles() {
            axios.get(process.env.REACT_APP_SERVER + "/article/recommended")
                .then(response => {
                    setArticles(response.data)
                    desActivateLoader()
                })
                .catch(err => {
                    desActivateLoader()
                    if(process.env.MODE === "dev"){
                        console.log(err)
                    }
                })
        }

        fetchRecommendedArticles()
    }, []);

    return (
        <div className={"recommend-articles-div"}>
            <h2><img className={"recommendation-icon"} alt={"recommendation"} src={recommendation}/>Polecamy
            </h2>
            <ul className={"recommendation-list"}>
                {articles?.map((article, index) => (
                    <li key={index} className={"recommendation-list-el"}><a
                        href={`/article/${article?.url}`}>{article?.title}</a></li>
                ))}
            </ul>
        </div>
    )
}

export default RecommendedArticlesComponent