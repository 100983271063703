import logo from "../uploads/logo.png"
import {useEffect} from "react";

function Header() {
    useEffect(() => {
        function addEnterListener() {
            let searchInput = document.querySelector(".search-input");
            searchInput.addEventListener("keypress", event => {
                if (event.key === "Enter") {
                    let param = searchInput.value
                    window.location.href = `/article/search/${param}`
                }
            })
        }

        addEnterListener()
    }, []);


    function onSearchButtonClick() {
        let searchDiv = document.querySelector(".search-div");
        let searchInput = document.querySelector(".search-input");
        let closeButton = document.querySelector(".search-close-button");
        let searchButton = document.querySelector(".search-button");
        if (!searchDiv?.classList.contains("search-div-extended")) {
            searchDiv?.classList.add("search-div-extended")
            searchInput?.classList.add("search-input-extended")
            closeButton?.classList.add("search-close-button-extended")
        } else {
            searchButton.addEventListener("click", () => {
                    let param = searchInput.value
                    window.location.href = `/article/search/${param}`
                }
            )
        }
    }


    function onCloseButtonClick() {
        let searchDiv = document.querySelector(".search-div");
        let searchInput = document.querySelector(".search-input");
        let closeButton = document.querySelector(".search-close-button");
        searchDiv.classList.remove("search-div-extended")
        searchInput.classList.remove("search-input-extended")
        closeButton.classList.remove("search-close-button-extended")
    }

    return (
        <header>
            <div className={"header-container"}>
                <div className={"logo-div"}>
                    <img onClick={() => window.location.href = "/"} alt={"logo"} src={logo}/>
                </div>
                <div className={"search-div"}>
                    <input className={"search-input"} placeholder={"Szukana fraza"}/>
                    <div className={"search-div-option"}>
                        <button onClick={onCloseButtonClick} className={"search-close-button"}>X</button>
                        <button onClick={onSearchButtonClick} className={"search-button"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor"
                                 className="bi bi-search" viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header