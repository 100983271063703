export function calculateMaxVotes(question) {
    let answers = question?.answers;
    let sum = 0;
    if(answers) {
        for (let answer of answers) {
            sum += answer?.votes
        }
    }

    return sum
}