import calendar from "../uploads/calendar.svg"
import comment from "../uploads/comment.svg"
import {useEffect, useState} from "react";
import StandingsTable from "../components/tables/StandingsTable";
import PichichiTable from "../components/tables/PichichiTable";
import like from "../uploads/like.png"
import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import axios from "axios";
import {calculateArticleTime, showComments} from "../components/CalculationsComponent";
import StandardArticlesComponent from "../components/StandardArticlesComponent";
import ConfirmEmail from "../components/ConfirmEmail";
import {useLoader} from "../context/LoaderContext";
import {Helmet} from "react-helmet-async";

function MainPage() {
    const [activeTable, setActiveTable] = useState("Tabela")
    const [mainArticle, setMainArticle] = useState([])
    const [importantArticles, setImportantArticles] = useState([])
    const [matchDay, setMatchDay] = useState(1)
    const [matches, setMatches] = useState([])
    const [matchDayOptions, setMatchDayOptions] = useState([])
    const [lastWeekCommentsCount, setLastWeekCommentsCount] = useState()
    const [topComments, setTopComments] = useState([])
    const {activateLoader, desActivateLoader} = useLoader()

    useEffect(() => {
        function fetchTopComments() {
            activateLoader()
            axios.get(process.env.REACT_APP_SERVER + "/article/last-week-top-comments")
                .then(response => {
                    setTopComments(response.data)
                    desActivateLoader()
                })
                .catch(err => {
                    console.log(err)
                    desActivateLoader()
                })
        }

        fetchTopComments()
    }, []);

    useEffect(() => {
        function fetchLastWeekCommentsCount() {
            axios.get(process.env.REACT_APP_SERVER + "/article/last-week-comments")
                .then(response => setLastWeekCommentsCount(response.data))
                .catch(err => console.log(err))
        }

        fetchLastWeekCommentsCount()
    }, []);

    useEffect(() => {
        function createMatchDayOptions() {
            let options = [];
            for (let i = 1; i <= 38; i++) {
                options.push(i)
            }
            setMatchDayOptions(options)
        }

        createMatchDayOptions()
    }, []);

    useEffect(() => {
        function fetchMatchDaySchedule() {
            axios.get(`${process.env.REACT_APP_SERVER}/season/matchday/${matchDay}`)
                .then(response => setMatches(response.data))
                .catch(err => console.log(err))
        }

        if (matchDay != null)
            fetchMatchDaySchedule()
    }, [matchDay]);

    useEffect(() => {

        function fetchCurrentMatchDay() {
            axios.get(process.env.REACT_APP_SERVER + "/season/matchday")
                .then(response => {
                    setMatchDay(response.data)
                })
                .catch(err => console.log(err))
        }

        fetchCurrentMatchDay()
    }, []);

    useEffect(() => {

        function getArticles() {
            activateLoader()
            axios.get(process.env.REACT_APP_SERVER + "/article-type", {params: {type: "MAIN"}})
                .then(response => {
                    setMainArticle(response.data.content)
                    desActivateLoader()
                })
                .catch(err => {
                    console.log(err)
                    desActivateLoader()
                })


            axios.get(process.env.REACT_APP_SERVER + "/article-type", {params: {type: "IMPORTANT"}})
                .then(response => {
                    setImportantArticles(response.data.content)
                })
                .catch(err => console.log(err))
        }

        getArticles()
    }, []);


    useEffect(() => {

        window.addEventListener('load', function () {
            const hash = window.location.hash;
            if (hash === "#rank") {
                const element = document.getElementById('rank');
                if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
                }
            } else if (hash === "#schedule") {
                const element = document.getElementById('schedule');
                if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
                }
            }
        });
    }, []);

    useEffect(() => {
        function changeActiveTab() {
            let tabs = document.querySelectorAll(".table-tab");
            tabs.forEach(tab => {
                if (tab.textContent === activeTable) {
                    tab.classList.add("active")
                } else {
                    tab.classList.remove("active")
                }
            })

        }

        changeActiveTab()
    }, [activeTable]);


    function onPreviousMatchDayClick() {
        if (matchDay > 1) {
            let matchdayOption = document.getElementById(`matchday-select-option-${matchDay + -1}`);
            matchdayOption.selected = true
            setMatchDay(prevState => prevState - 1)
        }
    }

    function onNextMatchDayClick() {
        if (matchDay < 38) {
            let matchdayOption = document.getElementById(`matchday-select-option-${matchDay + +1}`);
            matchdayOption.selected = true
            setMatchDay(prevState => prevState + 1)
        }
    }


    function onBestCommentClick(topComment) {
        if (topComment?.parentCommentId === null) {
            window.location.href = `/article/${topComment?.articleUrl}/#article-with-replies-${topComment?.id}`
        } else {
            window.location.href = `/article/${topComment?.articleUrl}/#article-with-replies-${topComment?.parentCommentId}`
        }
    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Najnowsze informacje o lidze hiszpańskiej."}</title>
                <meta name="Najlepsze źródło informacji o hiszpańskiej piłce nożnej w Polsce. Aktualności, wyniki, analizy, oraz wiadomości z La Liga
                i Copa del Rey. Bądź na bieżąco z relacjami na żywo, transferami i najważniejszymi wydarzeniami z hiszpańskich boisk."/>
            </Helmet>
            <ConfirmEmail/>
            <main className={"main"}>
                <div className={"front-articles-div"}>
                    {mainArticle?.map((article, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundImage: `url(${article?.coverUrl})`
                            }}
                            className={"main-article-div"}>
                            <a
                                className={"main-article-a"}
                                href={"/article/" + article?.url}>
                                <div className={"main-article-info"}>
                                    <div className={"time-comments-div"}>
                                  <span className={"art-details-time"}>
                                <img alt={"calendar"} src={calendar}/> {calculateArticleTime(article?.addTime)}
                            </span>
                                        <span className={"art-details-comments"}>
                                <img alt={"comment"} src={comment}/>{showComments(article?.comments)}</span>
                                    </div>
                                    <p className={"art-details-title"}>{article?.title}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                    <div className={"second-articles-div"}>
                        {importantArticles?.map((article, index) => (
                            <div
                                style={{
                                    backgroundImage: `url(${article?.coverUrl})`
                                }}
                                className={"second-article-div"} key={index}>
                                <a
                                    className={"main-article-a"}
                                    href={"/article/" + article?.url}>
                                    <div className={"main-article-info"}>
                                        <div className={"time-comments-div"}>
                    <span className={"art-details-time"}>
                    <img alt={"calendar"} src={calendar}/> {calculateArticleTime(article?.addTime)}
                    </span>
                                            <span className={"art-details-comments"}>
                <img alt={"comment"} src={comment}/>{showComments(article?.comments)}
            </span>
                                        </div>
                                        <p className={"art-details-title"}>{article?.title}</p>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <StandardArticlesComponent/>
                        <div className={"more-articles-div"}>
                            <a href={"/news"}>Zobacz więcej aktualności >></a>
                        </div>
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <div id={"schedule"} className={"schedule-div"}>
                            <div className={"match-day-change-div"}>
                                <button onClick={onPreviousMatchDayClick}
                                        className={"match-day-change-button"}>&lt;</button>
                                <select onChange={event => setMatchDay(Number.parseInt(event.target.value))}
                                        value={matchDay}
                                        className={"match-day-change-button"}>
                                    {matchDayOptions?.map((matchDay, index) => (
                                        <option
                                            id={`matchday-select-option-${matchDay}`}
                                            value={matchDay} key={index}>Kolejka {matchDay}</option>
                                    ))}
                                </select>
                                <button onClick={onNextMatchDayClick}
                                        className={"match-day-change-button"}>&gt;</button>
                            </div>
                            <ul className={"schedule-list"}>
                                {matches?.map((match, index) => (
                                    <li key={index}>
                                        <p className={"team-name"}>{match?.homeTeamName}</p>
                                        <img
                                            className={"schedule-logo"}
                                            alt={"logo"}
                                            src={match?.homeTeamLogoUrl}/>
                                        <div className={"schedule-match-info"}>
                                            <span>{match?.time?.substring(0, 10)}</span>
                                            <p>{match?.scoreHome}:{match?.scoreAway}</p>
                                            <span>{match?.time?.substring(11, 16)}</span>
                                        </div>
                                        <img
                                            className={"schedule-logo"}
                                            alt={"logo"}
                                            src={match?.awayTeamLogoUrl}/>
                                        <p className={"team-name"}>{match?.awayTeamName}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div id={"rank"} className={"table-div"}>
                            <ul className={"table-tabs"}>
                                <li onClick={event => setActiveTable(event.target.textContent)}
                                    className={"table-tab"}>Tabela
                                </li>
                                <li onClick={event => setActiveTable(event.target.textContent)}
                                    className={"table-tab"}>Pichichi
                                </li>
                            </ul>
                            {activeTable === "Tabela" && (
                                <StandingsTable/>
                            )}
                            {activeTable === "Pichichi" && (
                                <PichichiTable/>
                            )}
                        </div>
                        <div className={"best-comments-div"}>
                            <h2>Estadio de Opiniones</h2>
                            <p>Najciekawsze komentarze z {lastWeekCommentsCount} napisanych w ciągu ostatniego
                                tygodnia</p>
                            <ul className={"best-comments-list"}>
                                {topComments?.map((topComment, index) => (
                                    <li key={index} className={"best-comments-list-el"}>
                                        <div className={"best-comments-info"}>
                                            <img alt={"avatar"} src={topComment?.authorAvatarUrl}/>
                                            <p>{topComment?.author}</p>
                                            <img className={"icon"} alt={"calendar"} src={calendar}/>
                                            <p>{topComment?.addTime.replace("T", " ")}</p>
                                            <img className={"icon"} alt={"like"} src={like}/>
                                            <p>{topComment?.likes}</p>
                                        </div>
                                        <div onClick={() => onBestCommentClick(topComment)}
                                             className={"best-comments-article"}>
                                            <p className={"best-comment-article-title"}>
                                                {topComment?.articleTitle}</p>
                                            <p className={"best-comment-content"}>
                                                {topComment?.content}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default MainPage