import axios from "axios";
import calendar from "../uploads/calendar-black.png";
import {calculateArticleTime, showComments} from "./CalculationsComponent";
import articleIcon from "../uploads/article-black.png";
import commentIcon from "../uploads/comments-black.png";
import {useState} from "react";
import {useNotification} from "../context/NotificationContext";
import {useLoader} from "../context/LoaderContext";

function ArticleTranslatedPreview({article, serPreviewActive}) {
    const [articlePreview, setArticlePreview] = useState(article)
    const {activateLoader, desActivateLoader} = useLoader()
    const {setNotification} = useNotification()


    const handleTagChange = (index, event) => {
        const newTags = articlePreview.tags.map((tag, idx) => {
            if (idx === index) {
                return event.target.value;
            }
            return tag;
        });
        const newArticlePreview = {
            ...articlePreview,
            tags: newTags
        };
        setArticlePreview(newArticlePreview)
    }

    function onArticlePublicClick(article) {
        activateLoader()
        axios.post(process.env.REACT_APP_SERVER + "/admin/article", article)
            .then(response => {
                onRejectClick(article.title)
                    .then(desActivateLoader())
                    .catch(err => (err))
                if (articlePreview?.fbPublic) {
                    activateLoader()
                    const params = {
                        photoUrl: article?.coverUrl
                    }
                    axios.post(process.env.REACT_APP_SERVER + "/admin/facebook-photo", null, {params: params})
                        .then(photoResponse => {
                            const data = {
                                message: `${article?.title} \nhttps://www.laligapolska.pl/article/${response.data}`,
                                attached_media: [{media_fbid: photoResponse.data?.id}]
                            }
                            axios.post(process.env.REACT_APP_SERVER + "/admin/facebook-post", data)
                                .then(() => {
                                    desActivateLoader()
                                    setNotification("Dodano wszystko pomyślnie.")
                                })
                                .catch(err => {
                                    desActivateLoader()
                                    setNotification("Dodano artykuł i zdjęcie do fb.")
                                    if (process.env.MODE === "dev") {
                                        console.log(err)
                                    }
                                })
                        })
                        .catch(err => {
                            desActivateLoader()
                            setNotification("Dodano tylko artykuł.")
                            if (process.env.MODE === "dev") {
                                console.log(err)
                            }
                        })
                }
            })
            .catch(err => {
                desActivateLoader()
                setNotification("Nie udało się dodać nic.")
                if (process.env.MODE === "dev") {
                    console.log(err)
                }
            })
    }

    async function onRejectClick(title) {
        let params = {
            articleTitle: title
        }
        axios.delete(process.env.REACT_APP_SERVER + "/admin/article-translate", {params: params})
            .then(() => console.log("Translated article " + title + " deleted"))
            .catch(err => {
                if (process.env.MODE === "dev") {
                    console.log(err)
                }
            })
    }

    function onXClick() {
        serPreviewActive(false)
    }

    function handleTitleChange(event) {
        const newArticle = {...articlePreview, title: event.target.value}
        setArticlePreview(newArticle)
    }

    function handleContentChange(event) {
        const newArticle = {...articlePreview, content: event.target.value}
        setArticlePreview(newArticle)
    }

    function handleTypeChange(event) {
        const newArticle = {...articlePreview, type: event.target.value}
        setArticlePreview(newArticle)
    }

    function handleCheckBoxChange(event) {
        const newArticle = {...articlePreview, isRecommended: event.target.checked}
        setArticlePreview(newArticle)
    }

    function handleFbPublicClick(event) {
        const newArticle = {...articlePreview, fbPublic: event.target.checked}
        setArticlePreview(newArticle)
    }

    return (
        <>
            <div className={"article-preview-div"}>
                <p onClick={onXClick}>X</p>
                <img alt={"cover"} src={articlePreview?.coverUrl}/>
                <textarea className={"articles-management-title-area"}
                          onChange={event => handleTitleChange(event)}
                          defaultValue={articlePreview?.title}></textarea>
                <textarea className={"articles-management-text-area"}
                          onChange={event => handleContentChange(event)}
                          defaultValue={articlePreview?.content}/>
                {articlePreview?.tags?.map((tag, tagIndex) => (
                    <input
                        key={tagIndex}
                        onChange={event => handleTagChange(tagIndex, event)}
                        defaultValue={tag}></input>
                ))}
                <label>STANDARD</label>
                <input type={"radio"} name={"type"} value={"STANDARD"} defaultChecked={true}
                       onChange={event => handleTypeChange(event)}/>
                <label>IMPORTANT</label>
                <input type={"radio"} name={"type"} value={"IMPORTANT"}
                       onChange={event => handleTypeChange(event)}/>
                <label>MAIN</label>
                <input type={"radio"} name={"type"} value={"MAIN"}
                       onChange={event => handleTypeChange(event)}/>
                <button onClick={() => onArticlePublicClick(articlePreview)}>Opublikuj</button>
                <button onClick={() => onRejectClick(articlePreview?.title)}>Odrzuć</button>
            </div>
            <div className={"article-content-div"}>
                <div className={"author-info"}>
                    <h1 className={"article-title"}>{articlePreview?.title}</h1>
                    <p className={"article-author"}>{articlePreview?.author}</p>
                    <div className={"article-time-div"}>
                        <span><img className={"icon"} alt={"calendar"}
                                   src={calendar}/>{calculateArticleTime(articlePreview?.addTime)}</span>
                        <span><img className={"icon"} alt={"source"} src={articleIcon}/>{articlePreview?.source}</span>
                    </div>
                    <p><img className={"icon"} alt={"comment"}
                            src={commentIcon}/>{showComments(articlePreview?.comments)}</p>
                </div>
                <img alt={"article"} className={"article-image"}
                     src={articlePreview?.coverUrl}/>
                <article
                    dangerouslySetInnerHTML={{__html: articlePreview?.content}}
                    className={"article-text"}/>
                {articlePreview?.tags.map((tag, index) => (
                    <p key={index}>{tag}</p>
                ))}
                <p>TYP {articlePreview?.type}</p>
                <p>Rekomendowany?</p>
                <input type={"checkbox"} onChange={event => handleCheckBoxChange(event)}/>
                <p>Publikacja FB?</p>
                <input type={"checkbox"} onChange={event => handleFbPublicClick(event)}/>
            </div>
        </>
    )
}

export default ArticleTranslatedPreview