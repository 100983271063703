import {useEffect, useState} from "react";
import axios from "axios";
import {useLoader} from "../../context/LoaderContext";

function StandingsTable() {
    const [standings, setStandings] = useState([])
    const {activateLoader, desActivateLoader} = useLoader()
    useEffect(() => {
        function fetchStandings() {
            activateLoader()
            axios.get(process.env.REACT_APP_SERVER + "/season/standings")
                .then(response => {
                    setStandings(response.data)
                    desActivateLoader()
                })
                .catch(err => {
                    if(process.env.MODE === "dev") {
                        console.log(err)
                    }
                    desActivateLoader()
                })
        }

        fetchStandings()
    }, []);
    return (
        <table className={"standings-table"}>
            <thead>
            <tr>
                <td>#</td>
                <td>L</td>
                <td>Drużyna</td>
                <td>M</td>
                <td>PKT</td>
                <td>W</td>
                <td>R</td>
                <td>P</td>
                <td>G+</td>
                <td>G-</td>
                <td>G+-</td>
            </tr>
            </thead>
            <tbody>
            {standings?.map((standing, index) => (
                <tr key={index}>
                    <td>{standing?.position}</td>
                    <td><img className={"schedule-logo"} alt={"logo"} src={standing?.logoUrl}/></td>
                    <td>{standing?.name}</td>
                    <td>{standing?.playedGames}</td>
                    <td>{standing?.points}</td>
                    <td>{standing?.won}</td>
                    <td>{standing?.draw}</td>
                    <td>{standing?.lost}</td>
                    <td>{standing?.goalsFor}</td>
                    <td>{standing?.goalsAgainst}</td>
                    <td>{standing?.goalsDiff}</td>

                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default StandingsTable