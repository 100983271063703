import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import {MobileMenuProvider} from "./context/MobileMenuContext";
import MobileMenu from "./components/MobileMenu";
import Footer from "./components/Footer";
import MainMenu from "./components/MainMenu";
import ArticlePage from "./pages/ArticlePage";
import Header from "./components/Header";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import AdminPanel from "./pages/AdminPanel";
import {LoaderProvider} from "./context/LoaderContext";
import LoaderComponent from "./components/LoaderComponent";
import {LoggedRoute} from "./routes/LoggedRoute";
import {AdminRoute} from "./routes/AdminRoute";
import UserInfoUpdate from "./pages/UserInfoUpdate";
import TagArticlesPage from "./pages/TagArticlesPage";
import AdminQuestion from "./pages/AdminQuestion";
import QuestionsArchivePage from "./pages/QuestionsArchivePage";
import ArticleEditPage from "./pages/ArticleEditPage";
import Policy from "./pages/Policy";
import Regulations from "./pages/Regulations";
import SearchArticlesPage from "./pages/SearchArticlesPage";
import NewsPage from "./pages/NewsPage";
import UserProfilePage from "./pages/UserProfilePage";
import {UnLoggedRoute} from "./routes/UnLoggedRoute";
import RememberPassPage from "./pages/RememberPassPage";
import ResetPassPage from "./pages/ResetPassPage";
import ErrorPage from "./pages/ErrorPage";
import {NotificationProvider} from "./context/NotificationContext";
import NotificationComponent from "./components/NotificationComponent";
import {HelmetProvider} from 'react-helmet-async';
import {CookiesProvider} from "./context/CookiesContext";
import CookiesComponent from "./components/CookiesComponent";
import {useEffect} from "react";
import {configureGtag} from "./components/GtagConfiguration";
import {useCookies} from "react-cookie";

function App() {
    const [cookies] = useCookies(["cookiesAccepted"])

    useEffect(() => {
        configureGtag(cookies?.cookiesAccepted)
    }, [cookies?.cookiesAccepted]);

    return (
        <HelmetProvider>
            <CookiesProvider>
                <CookiesComponent/>
                <NotificationProvider>
                    <NotificationComponent/>
                    <MobileMenuProvider>
                        <Header/>
                        <MobileMenu/>
                        <MainMenu/>
                        <LoaderProvider>
                            <LoaderComponent/>
                            <BrowserRouter>
                                <Routes>
                                    <Route path={"/"} element={<MainPage/>}/>
                                    <Route path={"/article/tag/:url"} element={<TagArticlesPage/>}/>
                                    <Route path={"/article/:url"} element={<ArticlePage/>}/>
                                    <Route path={"/register"} element={<LoggedRoute><RegisterPage/></LoggedRoute>}/>
                                    <Route path={"/login"} element={<LoggedRoute><LoginPage/></LoggedRoute>}/>
                                    <Route path={"/admin-panel"} element={<AdminRoute><AdminPanel/></AdminRoute>}/>
                                    <Route path={"/register/user-info"} element={<UserInfoUpdate/>}/>
                                    <Route path={"/admin-panel/question"}
                                           element={<AdminRoute><AdminQuestion/></AdminRoute>}/>
                                    <Route path={"/question-archive"} element={<QuestionsArchivePage/>}/>
                                    <Route path={"/article-edit"}
                                           element={<AdminRoute><ArticleEditPage/></AdminRoute>}/>
                                    <Route path={"/policy"} element={<Policy/>}/>
                                    <Route path={"/regulations"} element={<Regulations/>}/>
                                    <Route path={"/article/search/:searchParam"} element={<SearchArticlesPage/>}/>
                                    <Route path={"/news"} element={<NewsPage/>}/>
                                    <Route path={"/profile/:username"}
                                           element={<UnLoggedRoute><UserProfilePage/></UnLoggedRoute>}/>
                                    <Route path={"/remember-password"}
                                           element={<LoggedRoute><RememberPassPage/></LoggedRoute>}/>
                                    <Route path={"/reset-password"}
                                           element={<LoggedRoute><ResetPassPage/></LoggedRoute>}/>
                                    <Route path={"*"} element={<ErrorPage/>}/>
                                    <Route path={"/error"} element={<ErrorPage/>}/>

                                </Routes>
                            </BrowserRouter>
                            <Footer/>
                        </LoaderProvider>
                    </MobileMenuProvider>
                </NotificationProvider>
            </CookiesProvider>
        </HelmetProvider>
    );
}

export default App;
