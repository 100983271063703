import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

function setTokenRefreshTimer(jwtToken) {
    if (jwtToken) {
        let expiresIn = jwtDecode(jwtToken).exp;
        let currentTime = Date.now() / 1000;
        let refreshTime = (expiresIn - currentTime - 60) * 1000;

        setTimeout(async () => {
            const url = process.env.REACT_APP_SERVER + "/login/refresh-token";
            const data = {
                refreshToken: localStorage.getItem("refreshToken")
            };

            axios.post(url, data)
                .then(response => {
                    const newToken = "Bearer " + response.data;
                    localStorage.setItem("jwt", newToken);
                    setTokenRefreshTimer(newToken);
                }).catch(err => {
                    localStorage.removeItem("jwt")
                    localStorage.removeItem("refreshToken")
                    window.location.href = "/login"
                    console.log("Error during token refreshing." + err)
            })


        }, refreshTime);
    }
}

const jwtToken = localStorage.getItem("jwt");
if (jwtToken) {
    setTokenRefreshTimer(jwtToken);
}

function isTokenExpired(token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
}

function AxiosInterceptor() {
    axios.interceptors.request.use(
        async (config) => {
            let jwtToken = localStorage.getItem('jwt');
            if (jwtToken) {
                if (isTokenExpired(jwtToken)) {
                    localStorage.removeItem("jwt")
                    localStorage.removeItem("refreshToken")
                    window.location.href = "/login"
                }
                config.headers.Authorization = jwtToken;
            }
            return config;
        },
        (error) => {
            console.error( error);
            return Promise.reject(error);
        }
    );
}

export default AxiosInterceptor;
