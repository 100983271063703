import {useEffect, useState} from "react";
import {calculateMaxVotes} from "../functions/CalculateMaxVotes"
import axios from "axios";
import {useLoader} from "../context/LoaderContext";
import {useNotification} from "../context/NotificationContext";

function QuestionComponent() {

    const [question, setQuestion] = useState(null)
    const [isVoted, setIsVoted] = useState(null)
    const [token] = useState(localStorage.getItem("jwt"))
    const [maxVotes, setMaxVotes] = useState(null)
    const {activateLoader, desActivateLoader} = useLoader()
    const {setNotification} = useNotification()


    useEffect(() => {
        function fetchQuestion() {
            activateLoader()
            axios.get(process.env.REACT_APP_SERVER + "/public/questionnaire")
                .then(response => {
                    setQuestion(response.data)
                    desActivateLoader()
                })
                .catch(err => {
                    desActivateLoader()
                    if (process.env.MODE === "dev") {
                        console.log(err)
                    }
                })
        }

        fetchQuestion()
    }, []);


    function onQuestionSend(event) {
        event.preventDefault()
        if (!token) {
            window.location.href = "/login"
        } else if (isVoted) {
            setNotification("Już głosowałeś/aś")
        } else {
            const data = {
                answerId: event.target.elements.question.value
            }
            axios.post(process.env.REACT_APP_SERVER + "/user/questionnaire-vote", data)
                .then(response => setQuestion(response.data))
                .catch(err => {
                    setNotification("Nie udało się zapisać głosu.")
                    if (process.env.MODE === "dev") {
                        console.log(err)
                    }
                })
        }


    }

    useEffect(() => {
        function checkIsQuestionnaireVoted() {
            let params = {
                questionnaireId: question?.id
            }
            axios.get(process.env.REACT_APP_SERVER + "/user/questionnaire-voted", {params: params})
                .then(response => (setIsVoted(response.data)))
                .catch(err => {
                    if (process.env.MODE === "dev") {
                        console.log(err)
                    }
                })
        }

        if (token && question != null)
            checkIsQuestionnaireVoted()
    }, [question]);

    useEffect(() => {
        let maxVotes = calculateMaxVotes(question);
        setMaxVotes(maxVotes)
    }, [question]);

    return (
        <>
            <h2 id={"question-h2"}>Odpowiedz na pytanie :)</h2>
            {!isVoted ? (
                <div className="question-div">
                    <p className="question">{question?.question}</p>
                    <form onSubmit={event => onQuestionSend(event)} className="question-form">
                        {question?.answers?.map((answer, index) => (
                            <div key={index} className="question-el">
                                <input
                                    type="radio"
                                    name="question"
                                    defaultChecked={index === 0}
                                    value={answer?.id}
                                />
                                <label>{answer?.text}</label>
                            </div>
                        ))}
                        <button type="submit">Głosuj</button>
                    </form>
                </div>
            ) : (
                <div className="question-div">
                    <p className="question">{question?.question}</p>
                    {question?.answers?.map((answer, index) => (
                        <div key={index} className="question-el">
                            <span className={"question-span"}>{answer?.text}</span>
                            <progress value={answer?.votes} max={maxVotes}/>
                            <span>{(answer?.votes / maxVotes * 100).toFixed(0)}%</span>
                        </div>
                    ))}
                    <a id={"question-a"} href={"/question-archive"}>Archiwum wyników</a>

                </div>
            )}
        </>
    )
}


export default QuestionComponent