import {useEffect, useState} from "react";
import axios from "axios";
import {useLoader} from "../context/LoaderContext";

function ArticlesToTranslateManagement({setTab}) {
    const [articles, setArticles] = useState([])
    const {activateLoader, desActivateLoader} = useLoader()

    function fetchArticlesToTranslate() {
        activateLoader()
        axios.get(process.env.REACT_APP_SERVER + "/admin/articles-to-translate")
            .then(response => {
                setArticles(response.data)
                desActivateLoader()
            })
            .catch(err => {
                desActivateLoader()
                if (process.env.MODE === "dev") {
                    console.log(err)
                }
            })
    }

    useEffect(() => {
        fetchArticlesToTranslate()
    }, []);

    function onTranslateClick(article) {
        activateLoader()
        axios.post(process.env.REACT_APP_SERVER + "/admin/article-translate", article)
            .then(() => {
                desActivateLoader()
                setTab("translated")
            })
            .catch(err => {
                desActivateLoader()
                if (process.env.MODE === "dev") {
                    console.log(err)
                }
            })
    }

    function onRejectClick(articleTitle) {
        axios.delete(process.env.REACT_APP_SERVER + "/admin/articles-to-translate", {params: {title: articleTitle}})
            .then(() => fetchArticlesToTranslate())
            .catch(err => {
                if (process.env.MODE === "dev") {
                    console.log(err)
                }
            })
    }

    function onCopyClick(articleText) {
        navigator.clipboard.writeText(articleText)
            .then(() => alert("Skopiowano do schowka"))
            .catch(err => {
                if (process.env.MODE === "dev") {
                    console.log(err)
                }
            })

    }

    return (
        <div className={"articles-to-translate-management"}>
            {articles?.map((article, index) => (
                <ul key={index} className={"articles-management-list"}>
                    <img alt={"cover"} src={article?.coverUrl}/>
                    <h2>{article?.title}</h2>
                    <p>{article?.content}</p>
                    <div className={"admin-article-options-div"}>
                        <button onClick={() => onTranslateClick(article)}>Tłumacz</button>
                        <button onClick={() => onRejectClick(article?.title)}>Odrzuć</button>
                        <button onClick={() => onCopyClick(article?.content)}>Kopiuj</button>
                    </div>
                </ul>
            ))}

        </div>
    )
}

export default ArticlesToTranslateManagement