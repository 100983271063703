export function calculateArticleTime(addTime) {
    let difference = Date.now() - Date.parse(addTime);
    difference = Math.floor(difference / 1000);

    const pluralize = (count, one, few, many) => {
        if (count === 1) {
            return one;
        } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
            return few;
        } else {
            return many;
        }
    };

    if (difference < 60) {
        return "przed chwilą";
    } else if (difference < 3600) {
        let differenceMinutes = Math.floor(difference / 60);
        return `${differenceMinutes} ${pluralize(differenceMinutes, 'minutę', 'minuty', 'minut')} temu`;
    } else if (difference < 86400) {
        let differenceHours = Math.floor(difference / 3600);
        return `${differenceHours} ${pluralize(differenceHours, 'godzinę', 'godziny', 'godzin')} temu`;
    } else {
        let differenceDays = Math.floor(difference / 86400);
        return `${differenceDays} ${pluralize(differenceDays, 'dzień', 'dni', 'dni')} temu`;
    }
}

export function showComments(commentsCount) {
    const pluralize = (count, one, few, many) => {
        if (count === 1) {
            return one;
        } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
            return few;
        } else {
            return many;
        }
    };

    return `${commentsCount} ${pluralize(commentsCount, 'komentarz', 'komentarze', 'komentarzy')}`;
}