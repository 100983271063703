import axios from "axios";
import logo from "../uploads/logo.png";
import {useState} from "react";
import {GoogleLogin} from '@react-oauth/google';
import {useLoader} from "../context/LoaderContext";
import {useNotification} from "../context/NotificationContext";
import {Helmet} from "react-helmet-async";

function LoginPage() {
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const {activateLoader, desActivateLoader} = useLoader()
    const {setNotification} = useNotification()


    function onLoginSubmit(event) {
        event.preventDefault()
        activateLoader()
        let data = {
            email: email,
            password: password,
        }
        axios.post(process.env.REACT_APP_SERVER + "/login", data)
            .then(response => {
                handleLogin(response)
                desActivateLoader()
            })
            .catch(err => {
                console.log(err)
                setNotification("Błędne dane logowania.")
                desActivateLoader()
            })
    }


    function handleGoogleLoginSuccess(response) {
        axios.post(process.env.REACT_APP_SERVER + "/login/google", response)
            .then(response => handleLogin(response))
            .catch(err => console.log(err))
    }

    function handleLogin(response) {
        localStorage.setItem("jwt", `Bearer ${response.data.jwt}`)
        localStorage.setItem("refreshToken", "Bearer " + response.data.refreshToken)
        if (response.data.isFirstLogin) {
            axios.patch(process.env.REACT_APP_SERVER + "/firstLogin")
                .catch(err => console.log(err))
            window.location.href = "/register/user-info"
        } else {
            window.location.href = "/"
        }
    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Zaloguj się"}</title>
                <meta name="Zaloguj się do swojego konta na LaLigaPolska.pl, aby uzyskać dostęp do spersonalizowanych treści, najnowszych informacji
                i analiz dotyczących hiszpańskiej piłki nożnej. Dołącz do naszej społeczności fanów La Liga już teraz!"/>
            </Helmet>
            <main className={"login-container"}>
                <img className={"logo"} alt={"logo"} src={logo}/>
                <h1>Zaloguj się</h1>
                <form onSubmit={event => onLoginSubmit(event)} className={"login-form"}>
                    <input onChange={event => setEmail(event.target.value)}
                           required={true} type={"email"} placeholder={"Adres email"}/>
                    <input type={"password"} onChange={event => setPassword(event.target.value)}
                           required={true}
                           placeholder={"Hasło"}/>
                    <button type={"submit"}>Zaloguj się</button>
                    <GoogleLogin
                        onSuccess={response => handleGoogleLoginSuccess(response)}
                        width={250}/>

                </form>
                <p className={"remember-password-p"}
                   onClick={() => window.location.href = "/remember-password"}>Nie pamiętam hasła</p>
                <p>Nie masz jeszcze konta?<a href={"/register"}> Zarejestruj się</a></p>
            </main>
        </div>
    )
}

export default LoginPage