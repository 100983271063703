function DeleteAccountConfirmComponent({isComponentVisible, setIsDeleteComponentVisible, handleUserDeleteConfirm}) {


    return (
        isComponentVisible && <div className={"action-confirm-container"}>
            <h1>Czy napewno chcesz usunąć konto?!</h1>
            <button id={"delete-yes"} onClick={handleUserDeleteConfirm} className={"action-confirm-button"}>Tak</button>
            <button id={"delete-no"} onClick={() => setIsDeleteComponentVisible(false)}
                    className={"action-confirm-button"}>Nie
            </button>
        </div>
    )
}

export default DeleteAccountConfirmComponent
