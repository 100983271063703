import RecommendedArticlesComponent from "../components/RecommendedArticlesComponent";
import QuestionComponent from "../components/QuestionComponent";
import StandardArticlesComponent from "../components/StandardArticlesComponent";
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";


function TagArticlesPage() {
    const [articles, setArticles] = useState([])
    const [page, setPage] = useState(1)
    let params = useParams();

    useEffect(() => {
        function fetchTagArticles() {
            if (!params) {
                window.location.href = "/error"
            }
            let parameters = {
                tagName: params.url
            }
            axios.get(process.env.REACT_APP_SERVER + "/article/tag", {params: parameters})
                .then(response => setArticles(response.data))
                .catch(err => console.log(err))
        }

        fetchTagArticles()
    }, []);

    function getMoreArticles() {
        setPage(page + 1)
        let parameters = {
            tagName: params.url,
            page: page
        }
        axios.get(process.env.REACT_APP_SERVER + "/article/tag", {params: parameters})
            .then(response => {
                setArticles(prevState => ({
                    ...prevState,
                    content: [...prevState?.content, ...response.data.content],
                    last: response.data.last
                }))
            })
            .catch(err => console.log(err))

    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{`Laligapolska.pl - Tag ${params?.url}`}</title>
                <meta
                    name="description"
                    content={`Przeglądaj najnowsze aktualności dotyczące ${params?.url} na LaLigaPolska.pl.
                     Znajdź najświeższe informacje, analizy i wiadomości związane z ${params?.url},
                      aby być na bieżąco z wydarzeniami w hiszpańskiej piłce nożnej.`}
                />
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <div className={"left-column-div"}>
                        <StandardArticlesComponent articles={articles}/>
                        {!articles?.last && articles?.length !== 0 && (
                            <button className={"classic-button"} onClick={getMoreArticles}>Załaduj więcej</button>)}
                        {articles?.length === 0 && (
                            <h1 className={"white-h1"}>Nie znaleziono żadnego artykułu</h1>
                        )}
                    </div>
                    <div className={"right-column-div"}>
                        <RecommendedArticlesComponent/>
                        <QuestionComponent/>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default TagArticlesPage