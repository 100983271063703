import ArticlesToTranslateManagement from "../components/ArticlesToTranslateManagement";
import {useState} from "react";
import ArticlesTranslatedManagement from "../components/ArticlesTranslatedManagement";
import {Helmet} from "react-helmet-async";

function AdminPanel (){
    const [activeTab, setActiveTab] = useState("to-translate")


    return(
        <div className={"main-div"}>
            <Helmet>
                <title>Laligapolska.pl - Panel Admina</title>
                <meta name="description" content="Panel administratora"/>
            </Helmet>
            <ul className={"admin-panel-menu"}>
                <li onClick={() => setActiveTab("to-translate")}>Artykuły do tłumaczenia</li>
                <li onClick={() => setActiveTab("translated")}>Artykuły do zatwierdzenia</li>
                <li onClick={() => window.location.href = "/admin-panel/question"}>Ankiety</li>
            </ul>
            {activeTab === "to-translate" && (<ArticlesToTranslateManagement setTab = {setActiveTab} />)}
            {activeTab === "translated" && (<ArticlesTranslatedManagement />)}

        </div>
    )
}
export default AdminPanel