import axios from "axios";
import {Helmet} from "react-helmet-async";

function AdminQuestion() {

    function onNewAnswerClick() {
        let form = document.querySelector(".main-div > form")
        let input = document.createElement("input")
        input.placeholder = "Odpowiedź"
        input?.classList.add("answer")
        let elementBefore = document.getElementById("add-que-button")
        form?.insertBefore(input, elementBefore)
    }

    function onFormSubmit(event) {
        event.preventDefault()
        let question = document.querySelector(".question")
        let inputs = document.querySelectorAll(".answer")
        let answers = []
        inputs.forEach(input => {
            let answer = {
                text: input.value,
                votes: 0
            }
            answers.push(answer)
        })

        let data = {
            question: question.value,
            isActive: true,
            answers: answers
        }
        axios.post(process.env.REACT_APP_SERVER + "/admin/questionnaire", data)
            .then(() => window.location.href = "/admin-panel")
            .catch(err => console.log(err))
    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>Laligapolska.pl - Zarządanie ankietami</title>
                <meta name="description" content="Panel administratora. Zarządanie ankietami."/>
            </Helmet>
            <p>Dodaj ankiete</p>
            <form className={"question-form"} onSubmit={event => onFormSubmit(event)}>
                <input className={"question"} placeholder={"Pytanie"}/>
                <button type={"button"} onClick={onNewAnswerClick}>Nowa odpowiedz</button>
                <button id={"add-que-button"} type={"submit"}>Dodaj ankiete</button>
            </form>

        </div>
    )
}

export default AdminQuestion