import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";

function ConfirmEmail() {
    const location = useLocation();

    useEffect(() => {
        const token = new URLSearchParams(location.search).get("token");
        if (token) {
            const params = {token};
            axios.patch(process.env.REACT_APP_SERVER + "/public/confirm-email", null, {params})
                .then(() => window.location.href = "/login")
                .catch(err => {
                    if (process.env.MODE === "dev") {
                        console.log(err)
                    }
                });
        }
    }, []);

    return null;
}

export default ConfirmEmail;
