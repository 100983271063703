import React from 'react';
import {BounceLoader} from 'react-spinners';
import {useLoader} from "../context/LoaderContext";

const LoaderComponent = () => {
    const {isLoaderActive} = useLoader();

    const loaderStyles = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 1,
        zIndex: 9999,
    };

    return (
        <>
            {isLoaderActive && (
                <div style={loaderStyles}>
                    <BounceLoader color="darkblue" size={100} loading={true}/>
                </div>
            )}
        </>
    );
};

export default LoaderComponent;
