import {useEffect, useState} from "react";
import axios from "axios";
import {useLoader} from "../context/LoaderContext";
import DeleteAccountConfirmComponent from "../components/DeleteAccountConfirmComponent";
import {useParams} from "react-router-dom";
import {useNotification} from "../context/NotificationContext";
import {Helmet} from "react-helmet-async";

function UserProfilePage() {
    const [loggedUserInfo, setLoggedUserInfo] = useState(JSON.parse(localStorage.getItem("userInfo")))
    const [userInfo, setUserInfo] = useState(null)
    const [commentsCount, setCommentsCount] = useState(null)
    const [editedFieldValue, setEditedFieldValue] = useState(null)
    const {activateLoader, desActivateLoader} = useLoader()
    const [isEdited, setIsEdited] = useState(false)
    const params = useParams()
    const [isDeleteWindowVisible, setIsDeleteWindowVisible] = useState(false)
    const [oldPassword, setOldPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const {setNotification} = useNotification()



    function handleUserDeleteConfirm() {
        const parameters = {
            userId: userInfo?.userId
        }
        axios.delete(process.env.REACT_APP_SERVER + "/user/delete", {params: parameters})
            .then(() => {
                localStorage.clear();
                localStorage.clear();
                window.location.href = "/"
            })
            .catch(err => console.log(err))
    }


    useEffect(() => {
        const parameters = {
            username: params.username
        }

        function fetchProfileInfo() {
            axios.get(process.env.REACT_APP_SERVER + "/user/userinfo", {params: parameters})
                .then(response => {
                    setUserInfo(response.data)
                })
                .catch(() => window.location.href = "/error")
        }

        fetchProfileInfo()
    }, []);

    useEffect(() => {
        function fetchUserCommentsCount() {
            const params = {
                userId: userInfo?.userId
            }
            axios.get(process.env.REACT_APP_SERVER + "/comments/count", {params: params})
                .then(response => setCommentsCount(response.data))
                .catch(err => console.log(err))
        }

        if (userInfo)
            fetchUserCommentsCount()
    }, [userInfo]);

    function hideProfileEdit(name) {
        document.getElementById(`${name}-input`)?.classList.remove("active")
        document.getElementById(`${name}-el`)?.classList.remove("hidden")
        document.getElementById(`${name}-ok`)?.classList.remove("active")
        document.getElementById(`${name}-edit`)?.classList.remove("hidden")
        document.getElementById(`${name}-anuluj`)?.classList.remove("active")

        setIsEdited(false)
    }

    function updateInformation(editedField) {
        activateLoader()
        let name = null
        let info = userInfo
        switch (editedField) {
            case "imie":
                info.name = editedFieldValue
                name = "imie"
                break
            case "wiek":
                info.yearsOld = editedFieldValue
                name = "wiek"
                break
            case "pilkarz":
                info.favouritePlayer = editedFieldValue
                name = "pilkarz"
                break
            case "team":
                info.favouriteTeam = editedFieldValue
                name = "team"
                break
            default:
                console.log("Wrong edited field name.")

        }
        axios.patch(process.env.REACT_APP_SERVER + "/user-info/edit", info)
            .then(() => {
                localStorage.setItem("userInfo", JSON.stringify(info))
                desActivateLoader()
                setNotification("Zaktualizowano profil użytkownika.")
            })
            .catch(err => {
                desActivateLoader()
                console.log(err)
                setNotification("Nie udało się zaktualizować profilu.")
            })
        hideProfileEdit(name)

    }

    function onEditClick(event, name) {
        if (!isEdited) {
            let input = document.getElementById(`${name}-input`)
            input?.classList.add("active");
            document.getElementById(`${name}-el`)?.classList.add("hidden")
            document.getElementById(`${name}-ok`)?.classList.add("active")
            document.getElementById(`${name}-anuluj`)?.classList.add("active")
            event.target.classList?.add("hidden")
            input?.focus()
            setIsEdited(true)
        }

    }

    function onAvatarChange(event) {
        if (event.target != null) {
            activateLoader()
            let formData = new FormData();
            formData.set("avatarFile", event.target?.files[0])
            formData.set("username", loggedUserInfo?.username)
            let headers = {
                'Content-Type': 'multipart/form-data'
            }
            axios.patch(process.env.REACT_APP_SERVER + "/userinfo/avatar", formData, {headers: headers})
                .then(response => {
                    desActivateLoader()
                    setNotification("Zaktualizowano avatar.")
                    setLoggedUserInfo(response.data)
                })
                .catch(err => {
                    desActivateLoader()
                    setNotification("Nie udało się zaktualizować avatara.")
                    console.log(err)
                })
        }
    }

    function onChangePassword(event) {
        let data = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }
        event.preventDefault()
        axios.patch(process.env.REACT_APP_SERVER + "/user/password", data)
            .then(response => {
                setNotification("Hasło zaktualizowane.")
            })
            .catch(err => {
                console.log(err)
                setNotification("Nie udało się zmienić hasła. Sprawdź poprawność wprowadzonych danych.")
            })
    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Profil użytkownika: " + params?.username}</title>
                <meta name="Zobacz profil użytkownika na LaLigaPolska.pl. Przeglądaj aktywności, ulubione artykuły i dane osobowe wybranego użytkownika.
                 Sprawdź informacje o jego udziale w społeczności oraz najnowsze interakcje związane z hiszpańską piłką nożną"/>
            </Helmet>
            <main className={"main"}>
                <div className={"bottom-row-div"}>
                    <header className={"profile-header"}>
                        <div className="profile-avatar-image-container">
                            <input onChange={event => onAvatarChange(event)}
                                   type="file" id="avatar-input" className="profile-avatar-file-input"/>
                            <img className={"profile-avatar-image"} alt={"avatar"} src={userInfo?.avatarUrl}/>
                        </div>

                        <h3>{userInfo?.username}</h3>
                        <h3>Dołączył/a {userInfo?.registerDate}</h3>
                    </header>
                    <main className={"profile-info-main"}>
                        <h2>O sobie</h2>
                        <div id={"imie-div"} className={"profile-element-div"}>
                            <input id={"imie-input"} type={"text"} className={"profile-input"}
                                   defaultValue={userInfo?.name}
                                   onChange={event => setEditedFieldValue(event.target.value)}/>
                            <h3 id={"imie-el"} className={"profile-element-header"}>Imię: {userInfo?.name}</h3>
                            {userInfo?.userId === loggedUserInfo?.userId && (
                                <button id={"imie-edit"} onClick={(event) => onEditClick(event, "imie")}
                                        className={"classic-button"}>EDYTUJ
                                </button>)}
                            <button id={"imie-ok"} onClick={() => updateInformation("imie")}
                                    className={"profile-classic-button"}>OK
                            </button>
                            <button id={"imie-anuluj"} onClick={() => hideProfileEdit("imie")}
                                    className={"profile-classic-button"}>ANULUJ
                            </button>
                        </div>
                        <div id={"wiek-div"} className={"profile-element-div"}>
                            <input id={"wiek-input"} min={1} max={99} type={"number"} className={"profile-input"}
                                   defaultValue={userInfo?.yearsOld}
                                   onChange={event => setEditedFieldValue(event.target.value)}/>
                            <h3 id={"wiek-el"} className={"profile-element-header"}>Wiek: {userInfo?.yearsOld}</h3>
                            {userInfo?.userId === loggedUserInfo?.userId && (
                                <button id={"wiek-edit"} onClick={(event) => onEditClick(event, "wiek")}
                                        className={"classic-button"}>EDYTUJ
                                </button>)}
                            <button id={"wiek-ok"} onClick={() => updateInformation("wiek")}
                                    className={"profile-classic-button"}>OK
                            </button>
                            <button id={"wiek-anuluj"} onClick={() => hideProfileEdit("wiek")}
                                    className={"profile-classic-button"}>ANULUJ
                            </button>
                        </div>
                        <div id={"pilkarz-div"} className={"profile-element-div"}>
                            <input id={"pilkarz-input"} type={"text"} className={"profile-input"}
                                   defaultValue={userInfo?.favouritePlayer}
                                   onChange={event => setEditedFieldValue(event.target.value)}/>
                            <h3 id={"pilkarz-el"} className={"profile-element-header"}>Ulubiony
                                piłkarz: {userInfo?.favouritePlayer}</h3>
                            {userInfo?.userId === loggedUserInfo?.userId && (
                                <button id={"pilkarz-edit"} onClick={(event) => onEditClick(event, "pilkarz")}
                                        className={"classic-button"}>EDYTUJ
                                </button>)}
                            <button id={"pilkarz-ok"} onClick={() => updateInformation("pilkarz")}
                                    className={"profile-classic-button"}>OK
                            </button>
                            <button id={"pilkarz-anuluj"} onClick={() => hideProfileEdit("pilkarz")}
                                    className={"profile-classic-button"}>ANULUJ
                            </button>
                        </div>
                        <div id={"team-div"} className={"profile-element-div"}>
                            <input id={"team-input"} type={"text"} className={"profile-input"}
                                   defaultValue={userInfo?.favouriteTeam}
                                   onChange={event => setEditedFieldValue(event.target.value)}/>
                            <h3 id={"team-el"} className={"profile-element-header"}>Ulubiony
                                zespół: {userInfo?.favouriteTeam}</h3>
                            {userInfo?.userId === loggedUserInfo?.userId && (

                                <button id={"team-edit"} onClick={(event) => onEditClick(event, "team")}
                                        className={"classic-button"}>EDYTUJ
                                </button>)}
                            <button id={"team-anuluj"} onClick={() => hideProfileEdit("team")}
                                    className={"profile-classic-button"}>ANULUJ
                            </button>
                            <button id={"team-ok"} onClick={() => updateInformation("team")}
                                    className={"profile-classic-button"}>OK
                            </button>
                        </div>
                        <h2>Statystyki</h2>
                        <h3>Dni w
                            serwisie: {Math.floor((Date.now() - new Date(userInfo?.registerDate).getTime()) / 1000 / 3600 / 24)}</h3>
                        <h3>Liczba komentarzy: {commentsCount}</h3>
                        {userInfo?.userId === loggedUserInfo?.userId && (
                            <>
                                <h3>Zmień hasło</h3>
                                <form className={"edit-password-form"} onSubmit={event => onChangePassword(event)}>
                                    <input
                                        placeholder={"Aktualne hasło"}
                                        onChange={event => setOldPassword(event.target.value)}
                                        type={"password"} required={true} className={"profile-input active"}/>
                                    <input
                                        maxLength={50}
                                        placeholder={"Nowe hasło"}
                                        onChange={event => setNewPassword(event.target.value)}
                                        pattern={"^(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{8,}$"}
                                        title={"Hasło musi zawierać co najmniej 8 znaków (1 mała litera, 1 wielka litera, 1 cyfra, 1 znak specjalny)."}
                                        required={true}
                                        type={"password"}
                                        className={"profile-input active"}/>
                                    <button type={"submit"} className={"profile-classic-button active"}>Zmień hasło
                                    </button>
                                </form>

                                <h3>Usuń konto</h3>
                                <h2>
                                    <button onClick={() => {
                                        setIsDeleteWindowVisible(true)
                                        window.scrollTo(0, 0)
                                    }}
                                            className={"classic-button"}>Usuń konto
                                    </button>
                                </h2>
                                <DeleteAccountConfirmComponent
                                    handleUserDeleteConfirm={handleUserDeleteConfirm}
                                    setIsDeleteComponentVisible={setIsDeleteWindowVisible}
                                    isComponentVisible={isDeleteWindowVisible}/>
                            </>
                        )}
                    </main>
                </div>
            </main>
        </div>
    )
}

export default UserProfilePage