import axios from "axios";
import logo from "../uploads/logo.png";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useLoader} from "../context/LoaderContext";

function UserInfoUpdate() {
    const [avatarFile, setAvatarFile] = useState(null)
    const [name, setName] = useState(null)
    const [yearsOld, setYearsOld] = useState(null)
    const [favouriteTeam, setFavouriteTeam] = useState(null)
    const [favouritePlayer, setFavouritePlayer] = useState(null)
    const {activateLoader, desActivateLoader} = useLoader()


    function createFormData() {
        let formData = new FormData();
        if (avatarFile) formData.append("avatarFile", avatarFile)
        if (name) formData.append("name", name)
        if (yearsOld) formData.append("yearsOld", yearsOld)
        if (favouriteTeam) formData.append("favouriteTeam", favouriteTeam)
        if (favouritePlayer) formData.append("favouritePlayer", favouritePlayer)
        return formData
    }


    function onUserInfoSubmit(event) {
        event.preventDefault()
        activateLoader()
        let formData = createFormData();
        axios.patch(process.env.REACT_APP_SERVER + "/user-info", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(() => {
                desActivateLoader()
                window.location.href = "/login"
            })
            .catch(err => {
                desActivateLoader()
                console.log(err)
            })

    }

    useEffect(() => {
        document.getElementById('file-input').addEventListener('change', function (event) {
            const fileName = event.target.files.length ? event.target.files[0].name : '';
            const fileNameSpan = document.querySelector('.file-name');
            if (fileName) {
                fileNameSpan.textContent = fileName;
                event.target.classList.add('has-file');
                setAvatarFile(event.target.files[0])
            } else {
                fileNameSpan.textContent = '';
                event.target.classList.remove('has-file');
            }
        });
    }, []);


    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{`Laligapolska.pl - Zaktualizuj dane użytkownika`}</title>
                <meta
                    name="description"
                    content="Zaktualizuj swoje dane osobowe na LaLigaPolska.pl. Skorzystaj z tej strony, aby edytować swoje informacje,
                     zmienić ustawienia konta i zarządzać preferencjami, aby dostosować doświadczenia na naszej stronie do swoich potrzeb"
                />
            </Helmet>
            <main className={"login-container"}>
                <img className={"logo"} alt={"logo"} src={logo}/>
                <h1>Dodaj informacje o sobie</h1>
                <form onSubmit={event => onUserInfoSubmit(event)} className={"login-form"}>
                    <div className="file-input-container">
                        <input type="file" id="file-input" className="file-input"/>
                        <label htmlFor="file-input" className="file-label">
                            <span className="placeholder-text">Dodaj avatar... (.jpg,.png)</span>
                            <span className="file-name"></span>
                        </label>
                    </div>
                    <input onChange={event => setName(event.target.value)}
                           placeholder={"Imię"}/>
                    <input onChange={event => setYearsOld(event.target.value)}
                           type={"number"} min={0} max={99} defaultValue={0}
                           placeholder={"Wiek"}/>
                    <input onChange={event => setFavouriteTeam(event.target.value)}
                           placeholder={"Ulubiona drużyna"}/>
                    <input onChange={event => setFavouritePlayer(event.target.value)}
                           placeholder={"Ulubiony piłkarz"}/>
                    <button type={"submit"}>Zapisz</button>
                    <button onClick={() => window.location.href = "/"}>Pomiń</button>
                </form>
            </main>
        </div>
    )
}

export default UserInfoUpdate