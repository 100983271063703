import {useCookiesWindow} from "../context/CookiesContext";
import {useCookies} from "react-cookie";

function CookiesComponent() {
    const [, setCookie] = useCookies(["cookiesAccepted"])
    const {isCookiesWindowActive, setIsCookiesWindowActive} = useCookiesWindow()


    return (
        isCookiesWindowActive && (
            <div className={"cookies-policy-div"}>
                <section id="polityka-cookies">
                    <header>
                        <h1>Polityka Cookies dla Portalu LaLigaPolska.pl</h1>
                    </header>

                    <section id="wprowadzenie">
                        <h2>1. Wprowadzenie</h2>
                        <p>Niniejsza Polityka Cookies wyjaśnia, w jaki sposób portal LaLigaPolska.pl wykorzystuje pliki
                            cookies
                            oraz inne technologie związane z przechowywaniem danych. Pliki cookies to małe pliki
                            tekstowe,
                            które
                            są przechowywane na urządzeniu użytkownika (komputerze, tablecie, smartfonie) podczas
                            korzystania z
                            naszej strony internetowej.</p>
                    </section>

                    <section id="cel-wykorzystywania">
                        <h2>2. Cel wykorzystywania plików cookies</h2>
                        <p>Na portalu LaLigaPolska.pl używamy plików cookies wyłącznie w celu zapewnienia prawidłowego
                            działania
                            naszej strony oraz umożliwienia użytkownikom korzystania z podstawowych funkcji, takich
                            jak:</p>
                        <ul>
                            <li>Logowanie się na konto użytkownika,</li>
                            <li>Utrzymanie sesji użytkownika po zalogowaniu,</li>
                            <li>Komentowanie artykułów i treści,</li>
                            <li>Udział w głosowaniach i innych interaktywnych elementach portalu.</li>
                        </ul>
                        <p>Pliki cookies, które wykorzystujemy, są niezbędne do korzystania z naszego portalu i
                            umożliwiają jego prawidłowe funkcjonowanie. Dodatkowo, używamy plików cookies do
                            zbierania anonimowych danych analitycznych za pomocą Google Analytics, co pozwala nam
                            na poprawę jakości i funkcjonalności naszej strony.
                            Pliki cookies nie są wykorzystywane do celów marketingowych ani do śledzenia zachowań
                            użytkowników poza naszym serwisem.</p>
                    </section>

                    <section id="rodzaje-cookies">
                        <h2>3. Rodzaje używanych plików cookies</h2>
                        <p>Na naszej stronie używamy następujących rodzajów plików cookies:</p>
                        <ul>
                            <li><strong>Cookies sesyjne:</strong> Są to tymczasowe pliki cookies, które są przechowywane
                                na
                                urządzeniu użytkownika do momentu wylogowania się z portalu lub zamknięcia przeglądarki.
                                Są
                                one
                                niezbędne do prawidłowego działania funkcji takich jak logowanie czy komentowanie.
                            </li>
                            <li><strong>Cookies stałe:</strong> Te pliki cookies są przechowywane na urządzeniu
                                użytkownika
                                przez określony czas lub do momentu ich usunięcia. Używamy ich w celu zapamiętania
                                preferencji
                                użytkownika, takich jak ustawienia językowe.
                            </li>
                        </ul>
                    </section>

                    <section id="zarzadzanie-cookies">
                        <h2>4. Zarządzanie plikami cookies</h2>
                        <p>Większość przeglądarek internetowych automatycznie akceptuje pliki cookies, ale użytkownicy
                            mają
                            możliwość zmiany ustawień przeglądarki, aby odrzucać pliki cookies lub informować o ich
                            wysyłaniu.
                            Należy jednak pamiętać, że wyłączenie plików cookies może wpłynąć na funkcjonalność naszego
                            portalu
                            i uniemożliwić korzystanie z niektórych jego funkcji, takich jak logowanie czy
                            komentowanie.</p>
                        <p>Aby zarządzać plikami cookies, prosimy o zapoznanie się z ustawieniami przeglądarki
                            internetowej.
                            Możliwość zarządzania plikami cookies znajduje się zazwyczaj w sekcji "Ustawienia" lub
                            "Preferencje".</p>
                    </section>

                    <section id="zmiany-polityki">
                        <h2>5. Zmiany w Polityce Cookies</h2>
                        <p>Zastrzegamy sobie prawo do dokonywania zmian w niniejszej Polityce Cookies w dowolnym
                            momencie.
                            Wszelkie zmiany będą publikowane na tej stronie, a data ostatniej aktualizacji będzie
                            widoczna
                            na
                            górze dokumentu. Zachęcamy do regularnego sprawdzania treści niniejszej polityki, aby być na
                            bieżąco
                            z naszymi praktykami dotyczącymi plików cookies.</p>
                    </section>

                    <section id="kontakt">
                        <h2>6. Kontakt</h2>
                        <p>Jeśli masz jakiekolwiek pytania dotyczące niniejszej Polityki Cookies lub chcesz uzyskać
                            więcej
                            informacji na temat sposobu, w jaki wykorzystujemy pliki cookies, prosimy o kontakt pod
                            adresem
                            e-mail: <a href="mailto:laligapolska2021@gmail.com">laligapolska2021@gmail.com</a> lub za
                            pośrednictwem formularza kontaktowego na naszej stronie.</p>
                    </section>

                    <footer>
                        <p><strong>Data ostatniej aktualizacji: 26.08.2024</strong></p>
                    </footer>
                </section>
                <div className={"cookies-close-div"}>
                    <button className={"classic-button"} onClick={() => {
                        setIsCookiesWindowActive(false)
                        setCookie('cookiesAccepted', true, {
                            expires: new Date(new Date().setMonth(new Date().getMonth() + 1))
                        })
                    }
                    }>AKCEPTUJ
                    </button>
                </div>
            </div>
        )

    )
}

export default CookiesComponent