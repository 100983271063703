import addUser from "../uploads/add-user.png"
import login from "../uploads/log-in.png"
import logoutIcon from "../uploads/log-out.png"
import {useEffect, useState} from "react";
import axios from "axios";
import {jwtDecode} from "jwt-decode";


function MainMenu() {
    const [token] = useState(localStorage.getItem("jwt"))
    const [userInfo, setUserInfo] = useState(null)
    const [userRole, setUserRole] = useState(null)


    useEffect(() => {
        function decodeToken() {

            try {
                const decoded = jwtDecode(token);
                const userRole = decoded.role;
                setUserRole(userRole)
                localStorage.setItem("role", userRole)
            } catch (err) {
                console.error('Invalid token:', err);
            }
        }

        if (token != null)
            decodeToken()
    }, []);

    useEffect(() => {
        function fetchUserInfo() {
            axios.get(process.env.REACT_APP_SERVER + "/user/userinfo/logged")
                .then(response => {
                    setUserInfo(response.data)
                    localStorage.setItem("userInfo", JSON.stringify(response.data))
                })
                .catch(err => {
                    if(process.env.MODE === "dev") {
                        console.log(err)
                    }
                })
        }

        if (token) {
            fetchUserInfo()
        }
    }, []);

    function closeMenu() {
        let mainDiv = document.querySelector(".main-menu-div");
        if (mainDiv.classList.contains("active")) {
            mainDiv.classList.remove("active")
        }
    }

    function logout() {
        localStorage.clear();
        localStorage.clear();
        window.location.href = "/"
    }

    return (
        <div className={"main-menu-div"}>
            {token && (
                <header className={"main-menu-header"}>
                    <div className={"main-menu-profile-info"}>
                        <img alt={"avatar"} className={"comment-avatar"} src={userInfo?.avatarUrl}/>
                        <p>{userInfo?.username}</p>
                    </div>
                </header>
            )}
            {!token && (
                <>
                    <button onClick={() => window.location.href = "/register"} className={"main-menu-button"}>
                        <img alt={"add-user"} src={addUser}/>
                        <p>Utwórz konto</p>
                    </button>
                    <button onClick={() => window.location.href = "/login"} className={"main-menu-button"}>
                        <img alt={"login"} src={login}/>
                        <p>Zaloguj się</p>
                    </button>
                </>
            )}
            {token && (
                <button onClick={logout} className={"main-menu-button"}>
                    <img alt={"logout"} src={logoutIcon}/>
                    <p>Wyloguj się</p>
                </button>
            )}
            {userRole === "ADMIN" && (
                <button onClick={() => window.location.href = "/admin-panel"} className={"main-menu-button"}>
                    <p>Panel Admina</p>
                </button>
            )}

            <button id={"close-menu-button"} onClick={closeMenu} className={"main-menu-button"}>
                <p>Zamknij menu</p>
            </button>
            <button onClick={() => window.location.href = "/"} className={"main-menu-button"}>
                <p>Strona główna</p>
            </button>
            <button onClick={() => window.location.href = `/profile/${userInfo?.username}`}
                    className={"main-menu-button"}>
                <p>Profil użytkownika</p>
            </button>
            <button onClick={() => window.location.href = "/article/1-chat"} className={"main-menu-button"}>
                <p>Czat</p>
            </button>
        </div>
    )

}

export default MainMenu