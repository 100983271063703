import {useEffect, useState} from "react";
import axios from "axios";
import {useLoader} from "../../context/LoaderContext";

function PichichiTable() {
    const [pichichiArray, setPichichiArray] = useState([])
    const {activateLoader, desActivateLoader} = useLoader()

    useEffect(() => {
        function fetchPichichi() {
            activateLoader()
            axios.get(process.env.REACT_APP_SERVER + "/season/scorers")
                .then(response => {
                    setPichichiArray(response.data)
                    desActivateLoader()
                })
                .catch(err => {
                    desActivateLoader()
                    if(process.env.MODE === "dev"){
                        console.log(err)
                    }
                })
        }

        fetchPichichi()
    }, []);
    return (
        <table className={"standings-table"}>
            <thead>
            <tr>
                <td>#</td>
                <td>Drużyna</td>
                <td>Piłkarz</td>
                <td>M</td>
                <td>G</td>
            </tr>
            </thead>
            <tbody>
            {pichichiArray?.map((scorer, index) => (
                <tr key={index}>
                    <td>{scorer?.id}</td>
                    <td><img
                        className={"schedule-logo"}
                        alt={"logo"} src={scorer?.teamUrl}/></td>
                    <td>{scorer?.name}</td>
                    <td>{scorer?.playedMatches}</td>
                    <td>{scorer?.goals}</td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default PichichiTable