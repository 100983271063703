import logo from "../uploads/logo.png";
import {useState} from "react";
import axios from "axios";
import {useNotification} from "../context/NotificationContext";
import {useLoader} from "../context/LoaderContext";
import {Helmet} from "react-helmet-async";

function RememberPassPage() {
    const [email, setEmail] = useState(null)
    const {setNotification} = useNotification()
    const {activateLoader, desActivateLoader} = useLoader()


    function onRememberSubmit(event) {
        event.preventDefault()
        activateLoader()
        let params = {
            email: email
        }
        axios.get(process.env.REACT_APP_SERVER + "/public/remember-password", {params: params})
            .then(() => {
                desActivateLoader()
                setNotification(`Wysłano link aktywacyjny na adres ${email}.`)
            })
            .catch(err => {
                desActivateLoader()
                setNotification("Nie udało się wysłać maila z potwierdzeniem.")
                console.log(err)
            })
    }

    return (
        <div className={"main-div"}>
            <Helmet>
                <title>{"Laligapolska.pl - Przypomnij Hasło"}</title>
                <meta name="Przypomnij sobie swoje hasło na LaLigaPolska.pl. Wprowadź swój adres e-mail, aby otrzymać instrukcje dotyczące resetowania hasła i
                 odzyskać dostęp do swojego konta. Bezpieczne i szybkie rozwiązanie w przypadku zapomnienia hasła."/>
            </Helmet>
            <main className={"login-container"}>
                <img className={"logo"} alt={"logo"} src={logo}/>
                <h1>Przypomnienie hasła</h1>
                <p>Jeżeli nie pamiętasz nazwy użytkownika do konta możemy Ci pomóc. Wystarczy, że podasz nam adres
                    e-mail na jaki zostało żałożone konto.</p>
                <form onSubmit={event => onRememberSubmit(event)} className={"login-form"}>
                    <input onChange={event => setEmail(event.target.value)}
                           required={true} type={"email"} placeholder={"Adres email"}/>
                    <button type={"submit"}>Wyślij</button>
                </form>
            </main>
        </div>
    )
}

export default RememberPassPage