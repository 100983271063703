import calendar from "../uploads/calendar.svg"
import chat from "../uploads/comment.svg"
import home from "../uploads/home.svg"
import rank from "../uploads/rank.png"
import menu from "../uploads/menu.svg"

function MobileMenu() {

    function showMainMenu() {
        let menuDiv = document.querySelector(".main-menu-div");
        if (!menuDiv?.classList.contains("active")) {
            menuDiv.classList.add("active");
        }
    }


    return (
        <div className={"mobile-menu-div"}>
            <div className={"mobile-menu-el"}>
                <a href={"/#schedule"}>
                    <img alt={"calendar"} src={calendar}/>
                    Terminarz</a>
            </div>
            <div className={"mobile-menu-el"}>
                <a href={"/article/1-chat"}>
                    <img alt={"chat"} src={chat}/>
                    Czat</a>
            </div>
            <div className={"mobile-menu-el"}>
                <a href={"/"}>
                    <img alt={"home"} src={home}/>
                    Główna</a>
            </div>
            <div className={"mobile-menu-el"}>
                <a href={"/#rank"}>
                    <img alt={"rank"} src={rank}/>
                    Tabela</a>
            </div>
            <div className={"mobile-menu-el"}>
                <a onClick={showMainMenu}>
                    <img alt={"menu"} src={menu}/>
                    Menu</a>
            </div>
        </div>
    )
}

export default MobileMenu